<template>
  <div class="filters">
    <div class="filter">
      <div class="filter-inputs">
        <select
          v-model="attributeKey"
          class="filter__question"
          @change="resetFilter()"
        >
          <option
            v-for="attribute in filterAttributes"
            :key="attribute.attributeKey"
            :value="attribute.attributeKey"
          >
            {{ $t(`FILTER.ATTRIBUTES.${attribute.attributeI18nKey}`) }}
          </option>
        </select>

        <select v-model="filterOperator" class="filter__operator">
          <option v-for="(operator, o) in operators" :key="o" :value="operator">
            {{ $t(`FILTER.OPERATOR_LABELS.${operator}`) }}
          </option>
        </select>

        <div class="filter__answer--wrap">
          <input
            v-model="values"
            type="text"
            class="answer--text-input"
            placeholder="Enter value"
            @keyup.enter="onEnter"
          />
        </div>
        <woot-button
          icon="dismiss"
          variant="clear"
          color-scheme="secondary"
          @click="removeFilter"
        />
      </div>
    </div>

    <p
      v-if="hasErrors && (internalShowErrors || showErrors)"
      class="filter-error"
    >
      {{ $t('FILTER.EMPTY_VALUE_ERROR') }}
    </p>

    <div v-if="showQueryOperator" class="filter__join-operator">
      <hr class="operator__line" />
      <span class="operator-container">
        {{ $t('FILTER.QUERY_DROPDOWN_LABELS.AND') }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => null,
    },
    filterAttributes: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: 'plain_text',
    },
    dataType: {
      type: String,
      default: 'plain_text',
    },
    operators: {
      type: Array,
      default: () => [],
    },
    showQueryOperator: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['resetFilter', 'removeFilter', 'submit', 'update:modelValue'],
  data() {
    return {
      internalShowErrors: false,
    };
  },
  computed: {
    attributeKey: {
      get() {
        if (!this.modelValue) return null;
        return this.modelValue.attribute_key;
      },
      set(value) {
        const payload = this.modelValue || {};
        this.$emit('update:modelValue', { ...payload, attribute_key: value });
      },
    },
    filterOperator: {
      get() {
        if (!this.modelValue) return null;
        return this.modelValue.filter_operator;
      },
      set(value) {
        const payload = this.modelValue || {};
        this.$emit('update:modelValue', { ...payload, filter_operator: value });
      },
    },
    values: {
      get() {
        if (!this.modelValue) return null;
        return this.modelValue.values;
      },
      set(value) {
        if (!this.internalShowErrors) {
          this.internalShowErrors = true;
        }
        const payload = this.modelValue || {};
        this.$emit('update:modelValue', { ...payload, values: value.trim() });
      },
    },
    query_operator: {
      get() {
        if (!this.modelValue) return null;
        return this.modelValue.query_operator;
      },
      set(value) {
        const payload = this.modelValue || {};
        this.$emit('update:modelValue', { ...payload, query_operator: value });
      },
    },
    hasErrors() {
      return !this.values;
    },
  },
  methods: {
    onEnter() {
      this.$emit('submit');
    },
    removeFilter() {
      this.$emit('removeFilter');
    },
    resetFilter() {
      this.$emit('resetFilter');
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }

  .operator-container {
    font-size: var(--font-size-medium);
    z-index: 1;
  }
}

.multiselect {
  margin-bottom: var(--space-zero);
}
</style>
