<template>
  <div
    class="user-thumbnail-box"
    :style="{ height: size, width: size }"
    :class="className"
  >
    <img
      v-if="!imgError && Boolean(src)"
      id="image"
      :src="src"
      :class="thumbnailClass"
      @error="onImgError()"
    />
    <WootAvatar
      v-else
      :username="username"
      :class="thumbnailClass"
      :size="avatarSize"
      :sent-by-bot="sentByBot"
    />
    <div
      v-if="showStatusIndicator"
      :class="`source-badge user-online-status user-online-status--${status}`"
      :style="statusStyle"
    />
  </div>
</template>
<script>
/**
 * Thumbnail Component
 * Src - source for round image
 * Size - Size of the thumbnail
 * Badge - Chat source indication { fb / telegram }
 * Username - User name for avatar
 */
import WootAvatar from 'components/widgets/Avatar.vue';

export default {
  name: 'WootThumbnail',
  components: {
    WootAvatar,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '40px',
    },
    badge: {
      type: String,
      default: 'fb',
    },
    username: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    shouldShowStatusAlways: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    sentByBot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgError: false,
    };
  },
  computed: {
    showStatusIndicator() {
      if (this.shouldShowStatusAlways) return true;
      return this.status && this.status !== 'offline';
    },
    avatarSize() {
      return Number(this.size.replace(/\D+/g, ''));
    },
    statusStyle() {
      const statusSize = `${this.avatarSize / 4}px`;
      return { width: statusSize, height: statusSize };
    },
    thumbnailClass() {
      const classname = this.hasBorder ? 'border' : '';
      return `user-thumbnail ${classname}`;
    },
  },
  watch: {
    src: {
      handler(value, oldValue) {
        if (value !== oldValue && this.imgError) {
          this.imgError = false;
        }
      },
    },
  },
  methods: {
    onImgError() {
      this.imgError = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-thumbnail-box {
  flex: 0 0 auto;
  max-width: 100%;
  position: relative;

  .user-thumbnail {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    // background: var(--color-woot) !important;
    object-fit: cover;

    &.border {
      border: 1px solid white;
    }
  }

  .source-badge {
    background: white;
    border-radius: var(--border-radius-small);
    bottom: var(--space-minus-micro);
    box-shadow: var(--shadow-small);
    height: var(--space-slab);
    position: absolute;
    right: 0;
    width: var(--space-slab);
  }

  .user-online-status {
    border-radius: 50%;
    bottom: var(--space-micro);

    &:after {
      content: ' ';
    }
  }

  .user-online-status--online {
    background: var(--g-500);
  }

  .user-online-status--busy {
    background: var(--r-500);
  }

  .user-online-status--offline {
    background: var(--b-600);
  }

  .user-online-status--away {
    background: var(--y-500);
  }
}
</style>
