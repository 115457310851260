<template>
  <div>
    <div class="filter">
      <div class="skill-inputs">
        <select
          v-model="currentSkillType"
          class="skill__type"
          :data-test-id="`skill-type-${index}`"
        >
          <option
            v-for="option in options"
            :key="option.key"
            :value="option.value"
            :hidden="noMoreOptionsLeft(option.value)"
          >
            {{ $t(option.label) }}
          </option>
        </select>

        <select
          v-model="currentSkill"
          class="skill__value"
          :data-test-id="`skill-value-${index}`"
        >
          <option
            v-for="item in skills"
            :key="item.key"
            :value="item"
            :hidden="isAlreadySelected(item)"
          >
            {{ item.name }}
          </option>
        </select>

        <woot-button
          icon="dismiss"
          variant="clear"
          color-scheme="secondary"
          :data-test-id="`remove-skill-${index}`"
          @click="removeSkill"
        />
      </div>
    </div>
  </div>
</template>
<script>
import skillsMixin from '../../../../mixins/skillsMixin';

export default {
  mixins: [skillsMixin],
  props: {
    index: { type: Number, default: null },
    skill: { type: Object, default: () => {} },
    onClose: { type: Function, default: () => {} },
    options: {
      type: Array,
      default: () => [],
    },
    skillsToUpdate: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['removeSkill', 'changeSkill', 'submit'],
  data() {
    return {
      currentSkillType: this.skill.skill_type,
      currentSkill: this.skill,
    };
  },
  computed: {
    skills() {
      return this.records.filter(
        skill => skill.skill_type === this.currentSkillType
      );
    },
  },
  watch: {
    currentSkill() {
      this.$emit('changeSkill', {
        skill: this.currentSkill,
        index: this.index,
      });
    },
    currentSkillType() {
      this.records.forEach(skill => {
        if (skill.skill_type === this.currentSkillType) {
          this.currentSkill = skill;

          this.$emit('changeSkill', {
            skill: this.currentSkill,
            index: this.index,
          });
        }
      });
    },
  },
  methods: {
    onEnter() {
      this.$emit('submit');
    },
    removeSkill() {
      this.$emit('removeSkill', this.index);
    },
    isAlreadySelected(item) {
      return this.skillsToUpdate.some(skill => skill.id === item.id);
    },
    noMoreOptionsLeft(optionValue) {
      // Filter skillsToUpdate and records arrays to get only elements with skill_type
      const filteredSkillsToUpdate = this.skillsToUpdate.filter(
        skill => skill.skill_type === optionValue
      );
      const filteredRecords = this.records.filter(
        record => record.skill_type === optionValue
      );

      // Check if the lengths of filtered arrays are the same
      const sameNumberOfSkills =
        filteredSkillsToUpdate.length === filteredRecords.length;

      return sameNumberOfSkills;
    },
  },
};
</script>
<style lang="scss" scoped>
.skill-inputs {
  display: flex;
}

.skill__type,
.skill__value {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}
</style>
