<template>
  <div
    class="aiCopilot"
    :class="{
      collapsed: isCollapsed,
      topMessage: messageData.isTopAICopilotMessage,
      lastMessage:
        messageData.isLastAICopilotMessageInGroup ||
        messageData.isOnlyAICopilotMessage,
    }"
    tabindex="0"
    @keypress.enter="onEnterKeyDown"
  >
    <copilot-header
      v-if="messageData.isTopAICopilotMessage"
      :is-collapsed="isCollapsed"
    />
    <copilot-content
      v-if="!isCollapsed"
      :allow-forwarding="allowForwarding"
      :is-collapsed="isCollapsed"
      @forwardMessage="onForwardAssistMessage"
    >
      <div class="icon-container">
        <div class="share-icon">
          <fluent-icon
            v-if="allowForwarding && !isCopilotInfoCard"
            icon="forward"
            size="60"
            view-box="0 0 40 40"
          />
        </div>
        <woot-button
          v-tooltip.right="$t('AGENT_ASSIST.RAW_DATA')"
          class="raw-data"
          icon="code"
          @click.stop="toggleRawData"
        />
      </div>
      <div class="message">
        <copilot-info-card
          v-if="isCopilotInfoCard"
          :message-data="messageData"
        />
        <copilot-structured-content-message
          v-else-if="!messageData.content"
          :message-data="messageData"
          @openImageModal="openImageModal"
        />
        <copilot-simple-message v-else :message-data="messageData" />
      </div>
    </copilot-content>
    <image-modal
      :show="showImageModal"
      :image-url="imageModalUrl"
      @close="closeImageModal"
    />
    <raw-data-modal
      :show="showRawData"
      :raw-data="messageData.content_attributes.items[0]"
      @close="toggleRawData"
    />
  </div>
</template>

<script>
import ImageModal from './../modal/ImageModal.vue';
import RawDataModal from './../modal/RawDataModal.vue';

import CopilotStructuredContentMessage from './CopilotStructuredContentMessage.vue';
import CopilotHeader from './CopilotHeader.vue';
import CopilotSimpleMessage from './CopilotSimpleMessage.vue';
import CopilotContent from './CopilotContent.vue';
import CopilotInfoCard from './copilotInfoCard/CopilotInfoCard.vue';

import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    ImageModal,
    RawDataModal,
    CopilotStructuredContentMessage,
    CopilotSimpleMessage,
    CopilotHeader,
    CopilotContent,
    CopilotInfoCard,
  },
  mixins: [],
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['collapseGroup'],
  data() {
    return {
      showImageModal: false,
      showRawData: false,
      imageModalUrl: '',
      MessageRenderer: window.MessageRenderer,
      stopForwarding: false,
    };
  },
  computed: {
    isCopilotInfoCard() {
      if (this.messageData.content_attributes.items) {
        // eslint-disable-next-line no-underscore-dangle
        return !!this.messageData.content_attributes.items[0]?.data?._cognigy
          ?._whisperAssist?.cardType;
      }
      return false;
    },
    showStructuredContentAvatar() {
      return this.isSentByBot || this.isSentByUser;
    },
    allowForwarding() {
      return this.messageData.forwardable && !this.isCopilotInfoCard;
    },
  },
  created() {
    bus.on(BUS_EVENTS.STOP_FORWARDING, () => {
      this.stopForwarding = true;
    });
  },
  methods: {
    onEnterKeyDown() {
      if (!this.allowForwarding) return;

      if (this.isCollapsed) {
        this.collapseGroup();
      } else {
        this.onForwardAssistMessage();
      }
    },
    collapseGroup() {
      this.$emit('collapseGroup');
    },
    onForwardAssistMessage() {
      if (this.allowForwarding && !this.stopForwarding) {
        this.$parent.$emit('forwardAssistMessage', this.messageData);
      }
      this.stopForwarding = false;
    },
    openImageModal(imageUrl) {
      this.imageModalUrl = imageUrl;
      this.showImageModal = true;
    },
    toggleRawData() {
      this.showRawData = !this.showRawData;
    },
    closeImageModal() {
      this.showImageModal = false;
    },
  },
};
</script>

<style lang="scss">
.topMessage {
  border-top: 1px dashed #0b369436;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 25px;
}

.lastMessage {
  border-bottom: 1px dashed #0b369436;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 25px;
}

.aiCopilot {
  transition: all 0.3s ease-out;
  background-color: #f1f5f9;
  margin-right: 20px;
  margin-left: 20px;
  width: 80%;
  overflow: hidden;
  border-left: 1px dashed #0b369436;
  border-right: 1px dashed #0b369436;
  border-bottom: 1px dashed #0b369436;

  :hover {
    .share-icon {
      visibility: visible;
    }
    .raw-data {
      visibility: visible;
    }
  }
}

.collapsed {
  height: 40px;
  transition: height 0.15s ease-out;
  border-bottom: 1px dashed #0b369436;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 25px;
}

.share-icon {
  visibility: hidden;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: background-color 0.3s ease-out;
  z-index: 2;
  padding-top: 11px;
  padding-right: 13px;

  svg {
    z-index: 2;
    color: #0b3694;
  }
}

.raw-data {
  visibility: hidden;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 30px !important;
  height: 30px;
  margin-top: 10px;
  transition: background-color 0.3s ease-out;
  z-index: 2;
  border-radius: 50%;
  background-color: #e2e2e2 !important;

  &:hover {
    background-color: #0b3694 !important;
  }
  svg {
    z-index: 2;
    color: #ffff;
  }
}

.icon-container {
  grid-column-start: 1;
  grid-row-start: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.message {
  grid-column-start: 2;
  grid-row-start: 1;
  display: flex;
  justify-content: center;
  padding-right: 25px;
}
</style>
