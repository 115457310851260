<template>
  <div class="wizard-body columns content-box small-9">
    <form class="row" @submit.prevent="addAgents">
      <div class="medium-12 columns">
        <page-header
          :header-title="headerTitle"
          :header-content="$t('TEAMS_SETTINGS.ADD.DESC')"
        />
      </div>

      <div class="medium-12 columns">
        <div v-if="v$.selectedAgents.$error">
          <p class="error-message">
            {{ $t('TEAMS_SETTINGS.ADD.AGENT_VALIDATION_ERROR') }}
          </p>
        </div>
        <agent-selector
          :agent-list="agentList"
          :agents-total="agentsTotal"
          :selected-agents="selectedAgents"
          :update-selected-agents="updateSelectedAgents"
          :is-working="isCreating"
          :submit-button-text="$t('TEAMS_SETTINGS.ADD.BUTTON_TEXT')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import PageHeader from '../../SettingsSubPageHeader.vue';
import AgentSelector from '../AgentSelector.vue';
import wootConstants from '../../../../../constants';
import useVuelidate from '@vuelidate/core';

export default {
  components: {
    PageHeader,
    AgentSelector,
  },
  mixins: [alertMixin],
  props: {
    team: {
      type: Object,
      default: () => {},
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },

  data() {
    return {
      selectedAgents: [],
      isCreating: false,
    };
  },

  computed: {
    ...mapGetters({
      agentList: 'agents/getUnmaskedAgents',
      agentsTotal: 'agents/getAgentsTotal',
    }),

    teamId() {
      return this.$route.params.teamId;
    },
    headerTitle() {
      return this.$t('TEAMS_SETTINGS.ADD.TITLE', {
        teamName: this.currentTeam.name,
      });
    },
    currentTeam() {
      return this.$store.getters['teams/getTeam'](this.teamId);
    },
  },

  mounted() {
    this.$store.dispatch('agents/getUnmasked', {
      page: 1,
    });
  },

  methods: {
    updateSelectedAgents(newAgentList) {
      this.v$.selectedAgents.$touch();
      this.selectedAgents = [...newAgentList];
    },
    selectAllAgents() {
      this.selectedAgents = this.agentList.map(agent => agent.id);
    },
    async addAgents() {
      this.isCreating = true;
      const { teamId, selectedAgents } = this;

      try {
        await this.$store.dispatch('teamMembers/create', {
          teamId,
          agentsList: selectedAgents,
        });
        this.$router.replace({
          name: 'settings_teams_finish',
          params: {
            page: 'new',
            teamId,
          },
        });
        this.$store.dispatch('teams/get');
      } catch (error) {
        this.showAlert(error.message, {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
      this.isCreating = false;
    },
  },
};
</script>
