export default {
  computed: {
    getTeamTitleErrorMessage() {
      let errorMessage = '';
      if (!this.v$.title.$invalid) {
        errorMessage = '';
      } else if (this.v$.title.required.$invalid) {
        errorMessage = this.$t('TEAMS_SETTINGS.FORM.NAME.REQUIRED_ERROR');
      } else if (this.v$.title.minLength.$invalid) {
        errorMessage = this.$t('TEAMS_SETTINGS.FORM.NAME.MINIMUM_LENGTH_ERROR');
      } else if (this.v$.title.validNameIsSafe.$invalid) {
        errorMessage = this.$t('TEAMS_SETTINGS.FORM.NAME.VALIDATION_ERROR');
      }
      return errorMessage;
    },
  },
};
