<template>
  <button
    :type="type"
    data-test-id="submit_button"
    :disabled="disabled"
    :class="computedClass"
    @click="onClick"
  >
    <fluent-icon v-if="!!iconClass" :icon="iconClass" class="icon" />
    <span>{{ buttonText }}</span>
    <spinner v-if="loading" />
  </button>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';

export default {
  name: 'WootSubmitButton',
  components: {
    Spinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'submit',
    },
  },
  emits: ['click'],
  computed: {
    computedClass() {
      return `button nice ${this.buttonClass || ' '}`;
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
button:disabled {
  opacity: 1;
  background-color: var(--w-100);

  &:hover {
    background-color: var(--w-100);
  }
}
</style>
