<template>
  <woot-dropdown-menu>
    <woot-dropdown-header
      :title="
        $t(
          agentId && agentId !== currentUserID
            ? 'SIDEBAR.SET_AGENT_AVAILABILITY_TITLE'
            : 'SIDEBAR.SET_AVAILABILITY_TITLE'
        )
      "
    />
    <woot-dropdown-item
      v-for="status in availabilityStatuses"
      :key="status.value"
      class="status-items"
    >
      <woot-button
        :color-scheme="status.disabled ? '' : 'secondary'"
        :data-test-id="`${status.value}_status_toggle`"
        :variant="status.disabled ? 'smooth' : 'clear'"
        class-names="status-change--dropdown-button"
        size="small"
        @click="changeAvailabilityStatus(status.value)"
      >
        <availability-status-badge :status="status.value" />
        {{ status.label }}
      </woot-button>
    </woot-dropdown-item>
    <woot-dropdown-divider />
  </woot-dropdown-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue3-click-away';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import WootDropdownHeader from 'shared/components/ui/dropdown/DropdownHeader.vue';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider.vue';
import AvailabilityStatusBadge from '../widgets/conversation/AvailabilityStatusBadge.vue';
import { ORDERED_AGENT_STATUS_KEY_TRANSLATOR } from 'shared/constants/statuses';
import { BUS_EVENTS } from '../../../shared/constants/busEvents';

export default {
  components: {
    WootDropdownHeader,
    WootDropdownDivider,
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatusBadge,
  },

  mixins: [clickaway],
  props: {
    agentId: {
      type: Number,
      default: null,
    },
    agentStatus: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isStatusMenuOpened: false,
      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentUserAvailability: 'getCurrentUserAvailability',
      currentAccountId: 'getCurrentAccountId',
      currentUserID: 'getCurrentUserID',
      getAccount: 'accounts/getAccount',
    }),
    currentUserAvailability() {
      return this.getCurrentUserAvailability;
    },
    availabilityStatuses() {
      const agentStatusKeys = Object.keys(ORDERED_AGENT_STATUS_KEY_TRANSLATOR);
      return agentStatusKeys.map(key => {
        const translationKey = ORDERED_AGENT_STATUS_KEY_TRANSLATOR[key];
        return {
          label: this.$t(
            `PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST.${translationKey}`
          ),
          value: key,
          disabled: this.agentStatus
            ? this.agentStatus === key
            : this.currentUserAvailability === key,
        };
      });
    },
  },

  methods: {
    openStatusMenu() {
      this.isStatusMenuOpened = true;
    },
    closeStatusMenu() {
      this.isStatusMenuOpened = false;
    },
    async changeAvailabilityStatus(availability) {
      const accountId = this.currentAccountId;
      if (this.isUpdating) {
        return;
      }

      const accountSettings = await this.getAccount(accountId);

      if (availability === 'offline' || availability === 'away') {
        await this.$store.dispatch('draftStore/deleteAll');
      }

      if (
        availability === 'busy' &&
        accountSettings.custom_attributes?.assign_to_busy_agent === false
      ) {
        await this.$store.dispatch('draftStore/deleteAll');
      }

      this.isUpdating = true;
      if (this.agentId && this.agentId !== this.currentUserID) {
        await this.$store.dispatch('setAgentStatus', {
          status: availability,
          accountId: accountId,
          agentId: this.agentId,
        });
      } else {
        await this.$store
          .dispatch('updateAvailability', {
            availability: availability,
            account_id: accountId,
          })
          .finally(() => {
            this.isUpdating = false;
          });
      }

      bus.emit(BUS_EVENTS.CLOSE_STATUS_MODAL);
    },
  },
};
</script>

<style lang="scss">
@import 'dashboard/assets/scss/variables';

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-micro) var(--space-smaller);
}

.status-view {
  display: flex;
  align-items: baseline;

  & &--title {
    color: var(--b-600);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    margin-left: var(--space-small);

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.status-change {
  .dropdown-pane {
    top: -132px;
    right: var(--space-normal);
  }

  .status-items {
    display: flex;
    align-items: baseline;
  }
}
</style>
