<template>
  <div class="row content-box full-height">
    <woot-wizard class="small-3 columns" :items="items" />
    <router-view />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: 'CreateStepWrap',
  setup() {
    const { tm } = useI18n();
    return { tm };
  },
  computed: {
    items() {
      return this.tm('TEAMS_SETTINGS.CREATE_FLOW.WIZARD');
    },
  },
};
</script>
