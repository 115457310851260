<template>
  <div
    v-if="!isCollapsed"
    class="CopilotContent"
    :class="{
      aiCopilotForwardable: allowForwarding,
    }"
    @click="onForwardAssistMessage"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    allowForwarding: {
      type: Boolean,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['forwardMessage'],
  methods: {
    onForwardAssistMessage() {
      this.$emit('forwardMessage');
    },
  },
};
</script>

<style lang="scss" scoped>
.CopilotContent {
  display: grid;
  grid-template-columns: 5% 95%;
  padding-bottom: 20px;
  padding-top: 10px;
  transition: all 0.3s ease-out;
  opacity: 0.3;
  :hover > & {
    opacity: 1;
    transition: all 0.3s ease-out;
  }
}

.aiCopilotForwardable {
  :hover > & {
    cursor: pointer;
  }
}
</style>
