import * as types from '../../mutation-types';
import {
  ConversationEndReachedParams,
  ConversationPageState,
  CurrentPageParams,
  FilterKey,
} from './conversationPage.types';
import { ActionContext } from 'vuex';

const state: ConversationPageState = {
  currentPage: {
    me: 0,
    unassigned: 0,
    all: 0,
    appliedFilters: 0,
  },
  hasEndReached: {
    me: false,
    unassigned: false,
    all: false,
    appliedFilters: false,
  },
};

export const getters = {
  getHasEndReached: ($state: ConversationPageState) => (filter: FilterKey) => {
    return $state.hasEndReached[filter];
  },
  getCurrentPageFilter:
    ($state: ConversationPageState) => (filter: FilterKey) => {
      return $state.currentPage[filter];
    },
  getCurrentPage: ($state: ConversationPageState) => {
    return $state.currentPage;
  },
};

export const actions = {
  setCurrentPage(
    {
      commit,
    }: Pick<
      ActionContext<ConversationPageState, ConversationPageState>,
      'commit'
    >,
    { filter, page }: CurrentPageParams
  ) {
    commit(types.default.SET_CURRENT_PAGE, { filter, page });
  },
  setEndReached(
    {
      commit,
    }: Pick<
      ActionContext<ConversationPageState, ConversationPageState>,
      'commit'
    >,
    { filter }: ConversationEndReachedParams
  ) {
    commit(types.default.SET_CONVERSATION_END_REACHED, { filter });
  },
  reset({
    commit,
  }: Pick<
    ActionContext<ConversationPageState, ConversationPageState>,
    'commit'
  >) {
    commit(types.default.CLEAR_CONVERSATION_PAGE);
  },
};

export const mutations = {
  [types.default.SET_CURRENT_PAGE]: (
    $state: ConversationPageState,
    { filter, page }: CurrentPageParams
  ) => {
    $state.currentPage[filter] = page;
  },
  [types.default.SET_CONVERSATION_END_REACHED]: (
    $state: ConversationPageState,
    { filter }: ConversationEndReachedParams
  ) => {
    if (filter === 'all') {
      $state.hasEndReached.unassigned = true;
      $state.hasEndReached.me = true;
    }
    $state.hasEndReached[filter] = true;
  },
  [types.default.CLEAR_CONVERSATION_PAGE]: ($state: ConversationPageState) => {
    $state.currentPage = {
      me: 0,
      unassigned: 0,
      all: 0,
      appliedFilters: 0,
    };

    $state.hasEndReached = {
      me: false,
      unassigned: false,
      all: false,
      appliedFilters: false,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
