<template>
  <vue-line-chart
    ref="chartRef"
    :data="collection"
    :options="{ ...defaultChartOptions, ...chartOptions }"
  />
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { Line as VueLineChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
);

const fontFamily =
  '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';

const defaultChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        fontFamily,
      },
    },
    tooltip: {
      enabled: false,
      cornerRadius: 3,
    },
  },
  scales: {
    x: {
      offset: true,
      ticks: {
        fontFamily,
        beginAtZero: false,
      },
      grid: {
        drawOnChartArea: false,
      },
    },
    y: {
      ticks: {
        fontFamily,
        beginAtZero: false,
      },
      grid: {
        drawOnChartArea: true,
      },
    },
  },
};

export default {
  name: 'LineChart',
  components: {
    VueLineChart,
  },
  props: {
    collection: {
      type: Object,
      default: () => ({}),
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const chartRef = ref(null);

    const renderChart = () => {
      if (chartRef.value?.chartInstance) {
        chartRef.value.chartInstance.update();
      }
    };

    onMounted(renderChart);
    watch(() => props.collection, renderChart, { deep: true });

    return {
      chartRef,
      defaultChartOptions,
    };
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
}
</style>
