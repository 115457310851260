<template>
  <div class="column content-box">
    <div class="button--fixed-right-top">
      <woot-button
        color-scheme="success"
        icon="add-circle"
        data-test-id="add-skill-button"
        @click="openAddPopup()"
      >
        {{ $t('SKILLS_MGMT.HEADER_BTN_TXT') }}
      </woot-button>
    </div>
    <!-- List Skills -->
    <div class="row">
      <div class="flex-auto max-width-large-8 columns with-right-space">
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="no-items-error-message"
        >
          {{ $t('SKILLS_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('SKILLS_MGMT.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table"
          data-test-id="skills-list"
        >
          <thead>
            <!-- Header -->
            <th
              v-for="thHeader in tm('SKILLS_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(skillItem, index) in records" :key="skillItem.name">
              <!-- Name  -->
              <td class="name" :data-test-id="`skill-name-${index}`">
                {{ skillItem.name }}
              </td>
              <!-- Type -->
              <td :data-test-id="`skill-type-${index}`">
                {{ $t(getTranslatedSkillType(skillItem.skill_type)) }}
              </td>
              <!-- Action Buttons -->
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('SKILLS_MGMT.EDIT.BUTTON_TEXT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  icon="edit"
                  :data-test-id="`edit-skill-${index}`"
                  @click="openEditPopup(skillItem)"
                />
                <woot-button
                  v-tooltip.top="$t('SKILLS_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :data-test-id="`delete-skill-${index}`"
                  :is-loading="loading[skillItem.id]"
                  @click="openDeletePopup(skillItem, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('SKILLS_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show="showAddPopup" :on-close="hideAddPopup">
      <add-skill :on-close="hideAddPopup" :options="skillTypeOptions" />
    </woot-modal>
    <!-- Edit Skill -->
    <woot-modal :show="showEditPopup" :on-close="hideEditPopup">
      <edit-skill
        v-if="showEditPopup"
        :id="selectedSkill.id"
        :edit-name="selectedSkill.name"
        :edit-type="selectedSkill.skill_type"
        :on-close="hideEditPopup"
        :options="skillTypeOptions"
      />
    </woot-modal>

    <!-- Delete Skill Response -->
    <woot-delete-modal
      data-test-id="delete-skill-modal"
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('SKILLS_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddSkill from './AddSkill.vue';
import EditSkill from './EditSkill.vue';
import wootConstants from '../../../../constants';
import skillsMixin from '../../../../mixins/skillsMixin';
import WootUIKit from 'components';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SkillsWrapper',
  components: {
    WootDeleteModal: WootUIKit.DeleteModal,
    WootModal: WootUIKit.Modal,
    WootButton: WootUIKit.Button,
    WootLoadingState: WootUIKit.LoadingState,
    AddSkill,
    EditSkill,
  },
  mixins: [skillsMixin],
  setup() {
    const { tm } = useI18n();
    return { tm };
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedSkill: {},
      skillAPI: {
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'skills/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('SKILLS_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedSkill.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('SKILLS_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedSkill.name
      }`;
    },
    deleteMessage() {
      return `${this.$t('SKILLS_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedSkill.name
      } ?`;
    },
  },
  mounted() {
    // Fetch API Call
    this.$store.dispatch('skills/get');
  },
  methods: {
    showAlert(message, action) {
      // Reset loading, current selected agent
      this.loading[this.selectedSkill.id] = false;
      this.selectedSkill = {};
      // Show message
      this.skillAPI.message = message;
      bus.emit('newToastMessage', message, action);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },

    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedSkill = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedSkill = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedSkill.id] = true;
      this.closeDeletePopup();
      this.deleteSkill(this.selectedSkill.id);
    },
    deleteSkill(id) {
      this.$store
        .dispatch('skills/deleteSkill', id)
        .then(() => {
          this.showAlert(this.$t('SKILLS_MGMT.DELETE.API.SUCCESS_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
          this.$store.dispatch('agents/getUnmasked');
        })
        .catch(() => {
          this.showAlert(this.$t('SKILLS_MGMT.DELETE.API.ERROR_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">
.flex-auto {
  flex-basis: auto;
}

.max-width-large-8 {
  max-width: 130em;
}

.name {
  width: 14rem;
}
</style>
