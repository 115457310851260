import types from '../../mutation-types';
import getters, { getSelectedChatConversation } from './getters';
import actions from './actions';
import { findPendingMessageIndex } from './helpers';
import wootConstants from '../../../constants';
import { BUS_EVENTS } from '../../../../shared/constants/busEvents';

const state = {
  allConversations: [],
  listLoadingStatus: true,
  chatStatusFilter: wootConstants.STATUS_TYPE.OPEN,
  chatSortFilter: wootConstants.SORT_BY_TYPE.LATEST,
  currentInbox: null,
  selectedChatId: null,
  appliedFilters: [],
  conversationLastSeen: null,
  isFetching: false,
};

// mutations
export const mutations = {
  [types.SET_ALL_CONVERSATION]($state, conversationList) {
    const newAllConversations = [...$state.allConversations];
    conversationList.forEach(conversation => {
      // skip setting the conversation if is the current conversation selected
      if ($state.selectedChatId === conversation.id) {
        return;
      }

      const indexInCurrentList = newAllConversations.findIndex(
        c => c.id === conversation.id
      );
      if (indexInCurrentList < 0) {
        newAllConversations.push(conversation);
      } else {
        newAllConversations[indexInCurrentList] = conversation;
      }
    });
    $state.allConversations = newAllConversations;
  },
  [types.EMPTY_ALL_CONVERSATION]($state) {
    $state.allConversations = [];
    $state.selectedChatId = null;
  },
  [types.SET_CONVERSATION_LAST_SEEN]($state, timestamp) {
    $state.conversationLastSeen = timestamp;
  },
  [types.SET_ALL_MESSAGES_LOADED]($state) {
    const [chat] = getSelectedChatConversation($state);
    $state.allMessagesLoaded = true;
  },

  [types.CLEAR_ALL_MESSAGES_LOADED]($state) {
    const [chat] = getSelectedChatConversation($state);
    $state.allMessagesLoaded = false;
  },
  [types.CLEAR_CURRENT_CHAT_WINDOW]($state) {
    $state.selectedChatId = null;
  },

  [types.CLEAR_CURRENT_CHAT_HISTORY]($state) {
    const [chat] = getSelectedChatConversation($state);
    $state.allConversations = $state.allConversations.map(c => {
      if (c.id === chat.id) {
        return {
          ...c,
          messages: [],
        };
      }
      return c;
    });
  },

  [types.SET_PREVIOUS_CONVERSATIONS]($state, { id, data }) {
    if (data.length) {
      const [chat] = $state.allConversations.filter(c => c.id === id);

      // TODO: This is a temporary fix for the issue of duplicate messages (ticket ID: 34908)
      const filteredData = data.filter(
        message => !chat.messages.find(m => m.id === message.id)
      );

      chat.messages.unshift(...filteredData);

      chat.messages.sort((a, b) => {
        const aCreatedAt =
          a.content_attributes?.external_created_at || a.created_at;
        const bCreatedAt =
          b.content_attributes?.external_created_at || b.created_at;

        if (aCreatedAt === bCreatedAt) {
          return a.id - b.id;
        }

        return aCreatedAt - bCreatedAt;
      });
    }
  },

  [types.SET_NEXT_CONVERSATIONS](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);

      // Ensuring no duplicate messages are added
      const filteredData = data.filter(
        message => !chat.messages.find(m => m.id === message.id)
      );

      chat.messages.push(...filteredData);
    }
  },

  [types.SET_CURRENT_CHAT_WINDOW]($state, activeChat) {
    if (activeChat) {
      $state.selectedChatId = activeChat.id;
    }
  },

  [types.ASSIGN_AGENT]($state, assignee) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      const updatedChat = {
        ...chat,
        meta: {
          ...chat.meta,
          assignee,
        },
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === chat.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.ASSIGN_TEAM]($state, team) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      const updatedChat = {
        ...chat,
        meta: {
          ...chat.meta,
          team,
        },
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === chat.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.UPDATE_CONVERSATION_LAST_ACTIVITY](
    $state,
    { lastActivityAt, conversationId }
  ) {
    const [chat] = $state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      const updatedChat = {
        ...chat,
        last_activity_at: lastActivityAt,
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === conversationId
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.ASSIGN_PRIORITY]($state, priority) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      const updatedChat = {
        ...chat,
        priority,
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === chat.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.UPDATE_CONVERSATION_CUSTOM_ATTRIBUTES]($state, custom_attributes) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      const updatedChat = {
        ...chat,
        custom_attributes,
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === chat.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.CHANGE_CONVERSATION_STATUS](
    $state,
    { conversationId, status, snoozedUntil }
  ) {
    const conversation =
      getters.getConversationById($state)(conversationId) || {};
    if (conversation) {
      const updatedConversation = {
        ...conversation,
        snoozed_until: snoozedUntil,
        status,
      };
      const conversationIndex = $state.allConversations.findIndex(
        c => c.id === conversation.id
      );
      if (conversationIndex > -1) {
        $state.allConversations.splice(
          conversationIndex,
          1,
          updatedConversation
        );
      }
    }
  },

  [types.MUTE_CONVERSATION]($state) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      const updatedChat = {
        ...chat,
        muted: true,
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === chat.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.UNMUTE_CONVERSATION]($state) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      const updatedChat = {
        ...chat,
        muted: false,
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === chat.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.ADD_MESSAGE]($state, { message, rootState }) {
    const [chat] = getSelectedChatConversation({
      allConversations: $state.allConversations,
      selectedChatId: message?.conversation_id,
    });
    if (!chat) {
      return;
    }

    const pendingMessageIndex = findPendingMessageIndex(chat, message);
    if (pendingMessageIndex !== -1) {
      chat.messages.splice(pendingMessageIndex, 1, message);
    } else {
      const existingMessage = chat.messages.find(
        m => m.id === message.id && m.created_at === message.created_at
      );
      if (existingMessage) return;

      chat.messages.push(message);

      chat.messages.sort((a, b) => {
        const aCreatedAt =
          a.content_attributes?.external_created_at || a.created_at;
        const bCreatedAt =
          b.content_attributes?.external_created_at || b.created_at;

        if (aCreatedAt === bCreatedAt) {
          return a.id - b.id;
        }

        return aCreatedAt - bCreatedAt;
      });

      chat.timestamp = message.created_at;

      if (
        $state.selectedChatId === message.conversation_id &&
        rootState.ui.isConversationScrollbarAtBottom
      ) {
        window.bus.emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
      }
    }
  },

  [types.DELETE_MESSAGE]({ allConversations, selectedChatId }, message) {
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: message?.conversation_id,
    });
    if (!chat) return;

    const pendingMessageIndex = findPendingMessageIndex(chat, message);
    if (pendingMessageIndex !== -1) {
      chat.messages.splice(pendingMessageIndex, 1, message);
    } else {
      chat.messages.push(message);
      chat.timestamp = message.created_at;
      if (selectedChatId === message.conversation_id) {
        window.bus.emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
      }
    }
  },

  [types.ADD_CONVERSATION]($state, conversation) {
    const conversationExists = $state.allConversations.filter(
      e => e.id === conversation.id
    );

    if (!conversationExists) {
      $state.allConversations.push(conversation);
    }
  },

  [types.DELETE_CONVERSATION]($state, conversationId) {
    const { allConversations } = $state;
    const currentConversationIndex = allConversations.findIndex(
      c => c.id === conversationId
    );

    if (currentConversationIndex !== -1) {
      allConversations.splice(currentConversationIndex, 1);
    }
  },

  [types.UPDATE_CONVERSATION]($state, conversation) {
    const { allConversations } = $state;
    const currentConversationIndex = allConversations.findIndex(
      c => c.id === conversation.id
    );
    if (currentConversationIndex > -1) {
      const { messages, ...conversationAttributes } = conversation;
      const currentConversation = {
        ...allConversations[currentConversationIndex],
        ...conversationAttributes,
      };

      allConversations.splice(currentConversationIndex, 1, currentConversation);

      if ($state.selectedChatId === conversation.id) {
        window.bus.emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
      }
    } else if (!$state.appliedFilters.length > 0) {
      $state.allConversations.push(conversation);
    }
  },

  [types.SET_LIST_LOADING_STATUS]($state) {
    $state.listLoadingStatus = true;
  },

  [types.CLEAR_LIST_LOADING_STATUS]($state) {
    $state.listLoadingStatus = false;
  },

  [types.MARK_MESSAGE_READ]($state, { id, lastSeen }) {
    const [chat] = $state.allConversations.filter(c => c.id === id);
    if (chat) {
      chat.agent_last_seen_at = lastSeen;
    }
  },

  [types.CHANGE_CHAT_STATUS_FILTER]($state, data) {
    $state.chatStatusFilter = data;
  },

  [types.CHANGE_CHAT_SORT_FILTER]($state, data) {
    $state.chatSortFilter = data;
  },

  // Update assignee on action cable message
  [types.UPDATE_ASSIGNEE]($state, payload) {
    const [chat] = $state.allConversations.filter(c => c.id === payload.id);
    if (chat) {
      const updatedChat = {
        ...chat,
        meta: {
          ...chat.meta,
          assignee: payload.assignee,
        },
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === payload.id
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.UPDATE_CONVERSATION_CONTACT]($state, { conversationId, ...payload }) {
    const [chat] = $state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      const updatedChat = {
        ...chat,
        meta: {
          ...chat.meta,
          sender: payload,
        },
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === conversationId
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.SET_ACTIVE_INBOX]($state, inboxId) {
    $state.currentInbox = inboxId ? parseInt(inboxId, 10) : null;
  },

  [types.SET_CONVERSATION_CAN_REPLY]($state, { conversationId, canReply }) {
    const [chat] = $state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      const updatedChat = {
        ...chat,
        can_reply: canReply,
      };
      const chatIndex = $state.allConversations.findIndex(
        c => c.id === conversationId
      );
      if (chatIndex > -1) {
        $state.allConversations.splice(chatIndex, 1, updatedChat);
      }
    }
  },

  [types.CLEAR_CONTACT_CONVERSATIONS]($state, contactId) {
    const chats = $state.allConversations.filter(
      c => c.meta.sender.id !== contactId
    );
    $state.allConversations = chats;
  },

  [types.SET_CONVERSATION_FILTERS]($state, data) {
    $state.appliedFilters = data;
  },

  [types.CLEAR_CONVERSATION_FILTERS]($state) {
    $state.appliedFilters = [];
  },

  [types.CHANGE_INBOX]($state, inboxId) {
    const [chat] = getSelectedChatConversation($state);
    if (chat) {
      chat.inbox_id = inboxId;
    }
  },

  [types.UPDATE_CONVERSATION_INBOX]($state, { conversationId, inboxId }) {
    const [chat] = $state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      chat.inbox_id = inboxId;
    }
  },
};

export const conversationsStore = {
  state,
  getters,
  actions,
  mutations,
};

export default conversationsStore;
