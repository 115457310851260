<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.API_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.API_CHANNEL.DESC')"
      :data-test-id="'api_channel_header'"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: v$.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            :data-test-id="'channel_name'"
            @blur="v$.channelName.$touch"
          />
          <span v-if="v$.channelName.$error" class="message">
            <span v-if="v$.channelName.required.$invalid">{{
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
            <span v-else-if="v$.channelName.validNameIsSafe.$invalid">{{
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.VALIDATION_ERROR')
            }}</span>
          </span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: v$.webhookUrl.$error }">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.LABEL') }}
          <input
            v-model.trim="webhookUrl"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.PLACEHOLDER')
            "
            :data-test-id="'webhook_url'"
            @blur="v$.webhookUrl.$touch"
          />
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.SUBTITLE') }}
        </p>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.API_CHANNEL.SUBMIT_BUTTON')"
          :data-test-id="'create_api_channel'"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from '@vuelidate/validators';
import PageHeader from '../../SettingsSubPageHeader.vue';
import wootConstants from '../../../../../constants';
import WootSubmitButton from 'components/buttons/FormSubmitButton.vue';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'SettingsInboxesPageChannel',
  components: {
    WootSubmitButton,
    PageHeader,
  },
  mixins: [alertMixin],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      channelName: '',
      webhookUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: {
      required,
      validNameIsSafe: (value = '') => !/^=/.test(value),
    },
    webhookUrl: {
      shouldBeWebhookUrl: (value = '') =>
        value ? value.startsWith('http') : true,
    },
  },
  methods: {
    async createChannel() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      try {
        const apiChannel = await this.$store.dispatch('inboxes/createInbox', {
          name: this.channelName,
          channel: {
            type: 'api',
            webhook_url: this.webhookUrl,
          },
        });

        this.$router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
  },
};
</script>
