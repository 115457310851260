import { defineComponent, h, useSlots } from 'vue';

export default defineComponent({
  name: 'WootTabs',
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  render() {
    const slots = useSlots();
    const tabItems = slots
      .default()
      .flatMap(node => node.children || [node])
      .filter(node => node.type && node.type.name === 'WootTabsItem')
      .map((node, index) => {
        const propsData = node.props || {};
        propsData.index = index;
        return h(node.type, { ...propsData }, node.children);
      });

    return h(
      'ul',
      {
        class: { tabs: true },
      },
      tabItems
    );
  },
});
