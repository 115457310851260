import AssignableAgentsAPI from '../../../api/assignableAgents';
import { State } from './inboxAssignableAgents.types';
import { Agent, AgentList } from '../../types/agent.types';
import { UiFlags } from '../../types/ui.types';
import { ActionContext } from 'vuex';

const state: State = {
  records: {},
  uiFlags: {
    isFetching: false,
  },
};

export const types = {
  SET_INBOX_ASSIGNABLE_AGENTS_UI_FLAG: 'SET_INBOX_ASSIGNABLE_AGENTS_UI_FLAG',
  SET_INBOX_ASSIGNABLE_AGENTS: 'SET_INBOX_ASSIGNABLE_AGENTS',
};

export const getters = {
  getAssignableAgents: ($state: State) => (inboxId: number) => {
    const allAgents: AgentList = $state.records[inboxId] || [];
    return allAgents.filter((record: Agent) => record.confirmed);
  },
  getUIFlags($state: State): UiFlags {
    return $state.uiFlags;
  },
};

export const actions = {
  async fetch(
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    inboxIds: number[] | string[]
  ) {
    commit(types.SET_INBOX_ASSIGNABLE_AGENTS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload },
      } = await AssignableAgentsAPI.get(inboxIds);
      commit(types.SET_INBOX_ASSIGNABLE_AGENTS, {
        inboxId: inboxIds.join(','),
        members: payload,
      });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      commit(types.SET_INBOX_ASSIGNABLE_AGENTS_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_INBOX_ASSIGNABLE_AGENTS_UI_FLAG]($state: State, data: UiFlags) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_INBOX_ASSIGNABLE_AGENTS]: (
    $state: State,
    { inboxId, members }: { inboxId: number; members: AgentList }
  ) => {
    $state.records[inboxId] = members;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
