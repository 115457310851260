<template>
  <div class="row app-wrapper">
    <woot-sidebar
      data-test-id="sidebar"
      :route="currentRoute"
      :class="sidebarClassName"
      @open-notification-panel="openNotificationPanel"
      @toggle-account-modal="toggleAccountModal"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-label-popup="showAddLabelPopup"
    />
    <section class="app-content columns">
      <router-view />
      <command-bar />
      <account-selector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
        @show-create-account-modal="openCreateAccountModal"
      />
      <add-account-modal
        :show="showCreateAccountModal"
        @close-account-create-modal="closeCreateAccountModal"
      />
      <woot-key-shortcut-modal
        v-if="showShortcutModal"
        @close="closeKeyShortcutModal"
        @clickaway="closeKeyShortcutModal"
      />
      <notification-panel
        v-if="isNotificationPanel"
        @close="closeNotificationPanel"
      />
      <woot-modal :show="showAddLabelModal" :on-close="hideAddLabelPopup">
        <add-label-modal @close="hideAddLabelPopup" />
      </woot-modal>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommandBar from './commands/CommandBar.vue';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal.vue';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel.vue';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel.vue';
import WootSidebar from 'components/layout/Sidebar.vue';
import WootUIKit from 'components';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  name: 'DashboardRoute',
  components: {
    WootModal: WootUIKit.Modal,
    WootSidebar,
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
  },
  data() {
    return {
      isOnDesktop: true,
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
    };
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: 'ui/isSideMenuOpen',
    }),
    currentRoute() {
      return ' ';
    },
    sidebarClassName() {
      if (this.isSidebarOpen) {
        return '';
      }
      return 'closed-sidebar';
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    bus.on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  beforeUnmount() {
    bus.off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
    window.removeEventListener('resize', this.handleResize);
    bus.off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 1200) {
        this.$store.dispatch('ui/toggleSideMenuStatus', {
          status: false,
        });
      }
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.closed-sidebar {
  position: fixed;
  visibility: hidden;
  transition: transform 1s ease;
}
</style>
