<template>
  <div class="agent-table-container">
    <EasyDataTable
      :headers="columns"
      :items="tableData"
      hide-footer
      fixed-header
      :table-height="800"
      body-row-class-name="reports-agent-table-row"
      @click-row="toggleStatusModal"
    >
      <template #item-agent="row">
        <div class="row-user-block">
          <WootThumbnail
            :src="row.thumbnail"
            size="32px"
            :username="row.agent"
            :status="row.status"
          />
          <div class="user-block">
            <h6 class="title text-truncate">{{ row.agent }}</h6>
            <span class="sub-title">{{ row.email }}</span>
          </div>
        </div>
      </template>
      <template #item-status="row">
        <div :class="`agent-table-status--${row.status}`">
          {{ $t(`OVERVIEW_REPORTS.AGENT_STATUS.${row.status.toUpperCase()}`) }}
        </div>
      </template>
      <template #item-open="row">
        <template v-if="row.open > 0">
          <a
            :href="`/app/accounts/${accountId}/dashboard?status=open&assignee_id=${row.agentId}`"
            >{{ row.open }}</a
          >
        </template>
        <template v-else>
          {{ row.open }}
        </template>
      </template>
      <template #item-queued="row">
        <template v-if="row.queued > 0">
          <a
            :href="`/app/accounts/${accountId}/dashboard?status=open&assignee_id=${row.agentId}&not_present=first_reply_created_at`"
          >
            {{ row.queued }}
          </a>
        </template>
        <template v-else>
          {{ row.queued }}
        </template>
      </template>
    </EasyDataTable>
    <div v-if="isLoading" class="agents-loader">
      <spinner />
      <span>{{
        $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')
      }}</span>
    </div>
    <empty-state
      v-else-if="!isLoading && !agentMetrics.length"
      :title="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_AGENTS')"
    />
    <div v-if="agentMetrics.length > 0" class="table-pagination">
      <table-footer
        :current-page="pageIndex"
        :total-count="totalAgents"
        :page-size="25"
        @page-change="onPageNumberChange"
      />
    </div>
    <woot-modal
      :show="showStatusModal"
      :on-close="toggleStatusModal"
      class="status-modal"
    >
      <woot-modal-header
        :header-title="agentDataName"
        :header-content="agentDataEmail"
      />
      <div class="status-modal-container">
        <availability-status
          :agent-id="agentDataId"
          :agent-status="agentDataStatus"
        />
      </div>
    </woot-modal>
  </div>
</template>

<script lang="jsx">
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootThumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import reportMixin from 'dashboard/mixins/reportMixin';
import { mapGetters } from 'vuex';
import AvailabilityStatus from '../../../../../../components/layout/AvailabilityStatus.vue';
import { BUS_EVENTS } from '../../../../../../../shared/constants/busEvents';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import 'vue3-easy-data-table/dist/style.css';
import WootModalHeader from '../../../../../../components/ModalHeader.vue';
import WootModal from '../../../../../../components/Modal.vue';

export default {
  name: 'AgentTable',
  components: {
    WootModal,
    WootModalHeader,
    EmptyState,
    Spinner,
    TableFooter,
    AvailabilityStatus,
    WootThumbnail,
  },
  mixins: [reportMixin],
  props: {
    totalAgents: {
      type: Number,
      default: 0,
    },
    agentMetrics: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  emits: ['page-change'],
  data() {
    return {
      currentSort: {
        fieldName: null,
        type: null,
      },
      sortOption: {
        sortChange: params => {
          const paramKeys = Object.keys(params);
          paramKeys.forEach(key => {
            if (params[key] === 'asc' || params[key] === 'desc') {
              this.currentSort = {
                fieldName: key,
                type: params[key],
              };
            }
          });
        },
      },

      showStatusModal: false,
      agentData: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    tableData() {
      const data = this.agentMetrics.map(agent => {
        return {
          agent: agent.name,
          agentId: agent.id,
          email: agent.email,
          thumbnail: agent.thumbnail,
          open: agent.metric.open || 0,
          queued: agent.metric.queued || 0,
          status: agent.availability,
          avg_first_response_time: this.formatSecondsField(
            agent.metric.avg_first_response_time
          ),
          avg_resolution_time: this.formatSecondsField(
            agent.metric.avg_resolution_time
          ),
          resolved: agent.metric.resolved,
        };
      });

      if (
        this.currentSort.fieldName === null ||
        this.currentSort.type === null
      ) {
        return data;
      }
      return data.sort((daton1, daton2) => {
        if (this.currentSort.type === 'asc') {
          return (
            daton1[this.currentSort.fieldName] <
            daton2[this.currentSort.fieldName]
          );
        }
        return (
          daton1[this.currentSort.fieldName] >
          daton2[this.currentSort.fieldName]
        );
      });
    },
    columns() {
      return [
        {
          value: 'agent',
          key: 'agentName',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT'
          ),
          fixed: 'left',
          align: 'left',
          width: 25,
        },
        {
          value: 'status',
          key: 'availability',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.STATUS'
          ),
          align: 'left',
          width: 10,
        },
        {
          value: 'open',
          key: 'open',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.OPEN'
          ),
          align: 'left',
          width: 10,
        },
        {
          value: 'queued',
          key: 'queued',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.QUEUED'
          ),
          align: 'left',
          width: 10,
        },
        {
          value: 'avg_first_response_time',
          key: 'avg_first_response_time',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AVG_FIRST_RESPONSE_TIME'
          ),
          align: 'left',
          width: 10,
        },
        {
          value: 'avg_resolution_time',
          key: 'avg_resolution_time',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AVG_RESOLUTION_TIME'
          ),
          align: 'left',
          width: 10,
        },
        {
          value: 'resolved',
          key: 'resolved',
          text: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.RESOLVED'
          ),
          align: 'left',
          width: 10,
        },
      ];
    },
    agentDataId() {
      return this.agentData?.agentId;
    },
    agentDataName() {
      return this.agentData?.agent;
    },
    agentDataEmail() {
      return this.agentData?.email;
    },
    agentDataStatus() {
      return this.agentData?.status;
    },
  },
  mounted() {
    bus.on(BUS_EVENTS.CLOSE_STATUS_MODAL, () => {
      this.showStatusModal = false;
    });
  },

  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    toggleStatusModal(row) {
      this.agentData = row;
      this.showStatusModal = !this.showStatusModal;
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  :deep(.vue3-easy-data-table) {
    th {
      background-color: #fafafa;
      font-size: var(--font-size-mini) !important;
      padding: var(--space-small) var(--space-two) !important;
    }
    td {
      padding: var(--space-one) var(--space-two) !important;
    }
  }
}

.table-pagination {
  margin-top: var(--space-normal);
  text-align: right;
}

.agents-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}

.agent-table-status--online {
  color: var(--g-600);
}

.agent-table-status--busy {
  color: var(--r-500);
}

.agent-table-status--offline {
  color: var(--b-600);
}

.agent-table-status--away {
  color: var(--y-700);
}

.status-modal-container {
  padding: 20px;
}
</style>
<style lang="scss">
.status-modal {
  .modal-container {
    width: 40rem;
  }
}
.row-user-block {
  align-items: center;
  display: flex;
  text-align: left;

  .user-block {
    display: flex;
    flex-direction: column;
    min-width: 0;
    .title {
      font-size: var(--font-size-small);
      margin: var(--zero);
      line-height: 1.3;
    }
    .sub-title {
      font-size: var(--font-size-mini);
    }
  }

  .user-thumbnail-box {
    margin-right: var(--space-small);
  }
}
</style>
