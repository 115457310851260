<template>
  <woot-modal :show="show" :on-close="onClose">
    <div class="content" data-test-id="contact-details-modal">
      <h4>
        <span v-if="name">{{ `${name}` }}</span>
        <span v-if="lastName">{{ `${lastName}` }}</span>
      </h4>
      <div class="table">
        <div
          v-if="data.birthday"
          class="container"
          data-test-id="contact-details-birthday"
        >
          <p class="key">
            {{ $t('CONVERSATION.ATTACHMENTS.CONTACT.BIRTHDAY') }}
          </p>
          <div class="sub-container">
            <p class="value">{{ data.birthday }}</p>
          </div>
        </div>
        <div
          v-if="addresses"
          class="container"
          data-test-id="contact-details-addresses"
        >
          <p class="key">
            {{ $t('CONVERSATION.ATTACHMENTS.CONTACT.ADDRESSES') }}
          </p>
          <div class="sub-container">
            <div
              v-for="(address, index) in addresses"
              :key="index"
              class="address"
            >
              <p>{{ address.type }}</p>
              <p>{{ renderStreet(address.street) }}</p>
              <p>{{ address.city }}</p>
              <p>{{ address.state }}</p>
              <p>{{ address.zip }}</p>
              <p>{{ address.country }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="emails"
          class="container"
          data-test-id="contact-details-emails"
        >
          <p class="key">
            {{ $t('CONVERSATION.ATTACHMENTS.CONTACT.EMAILS') }}
          </p>
          <div class="sub-container">
            <div v-for="(email, index) in emails" :key="index" class="value">
              <p>{{ email.type }}</p>
              <p>{{ email.email }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="phones"
          class="container"
          data-test-id="contact-details-phones"
        >
          <p class="key">
            {{ $t('CONVERSATION.ATTACHMENTS.CONTACT.PHONES') }}
          </p>
          <div class="sub-container">
            <div v-for="(phone, index) in phones" :key="index" class="value">
              <p>{{ phone.type }}</p>
              <p>{{ phone.phone }}</p>
            </div>
          </div>
        </div>
        <div v-if="urls" class="container" data-test-id="contact-details-urls">
          <p class="key">
            {{ 'URLs: ' }}
          </p>
          <div class="sub-container">
            <div v-for="(url, index) in urls" :key="index" class="value">
              <p>{{ url.type }}</p>
              <a :href="'//' + url.url" target="_blank">{{ url.url }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import WootModal from 'components/Modal.vue';

export default {
  components: { WootModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    name() {
      return this.data.name?.first_name || this.data.name?.firstName;
    },
    lastName() {
      return this.data.name?.last_name || this.data.name?.lastName;
    },
    addresses() {
      return this.data.addresses?.length > 0 ? this.data.addresses : null;
    },
    emails() {
      return this.data.emails?.length > 0 ? this.data.emails : null;
    },
    phones() {
      return this.data.phones?.length > 0 ? this.data.phones : null;
    },
    urls() {
      return this.data.urls?.length > 0 ? this.data.urls : null;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    renderStreet(street) {
      return street?.replace('\\r\\n', '\r\n');
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  min-width: 50px;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.table {
  display: table;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 300px;
}

.key {
  font-weight: bold;
  margin-top: 5px;
}

.value {
  white-space: pre-line;
  margin-top: 5px;
}
.content {
  display: flex;
}

.address {
  white-space: pre-line;
  margin-top: 15px;

  &:first-child {
    margin-top: 5px;
  }
}
</style>
