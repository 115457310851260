<template>
  <div class="wizard-body columns content-box small-9">
    <form class="row" @submit.prevent="addAgents()">
      <div class="medium-12 columns">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.AGENTS.TITLE')"
          :header-content="$t('INBOX_MGMT.ADD.AGENTS.DESC')"
          :data-test-id="'add_agents_header'"
        />
      </div>
      <div class="medium-7 columns">
        <div class="medium-12 columns">
          <label :class="{ error: v$.selectedAgents.$error }">
            {{ $t('INBOX_MGMT.ADD.AGENTS.TITLE') }}
            <multi-select
              ref="multiselect"
              v-model="selectedAgents"
              :options="accumulativeAgentList"
              track-by="id"
              label="name"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :placeholder="$t('INBOX_MGMT.ADD.AGENTS.PICK_AGENTS')"
              :data-test-id="'add_agents_select'"
              @select="v$.selectedAgents.$touch"
              @search-change="handleSearch"
            />
            <span v-if="v$.selectedAgents.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('INBOX_MGMT.AGENTS.BUTTON_TEXT')"
            :loading="isCreating"
            :data-test-id="'add_agents_to_inbox'"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';

import InboxMembersAPI from '../../../../api/inboxMembers';
import PageHeader from '../SettingsSubPageHeader.vue';
import useVuelidate from '@vuelidate/core';
import { ref } from 'vue';

export default {
  components: {
    PageHeader,
  },

  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },
  setup: () => {
    const multiselect = ref(null);
    return { v$: useVuelidate(), multiselect };
  },
  data() {
    return {
      selectedAgents: [],
      isCreating: false,
      accumulativeAgentList: [],
      currentPage: 1,
      searchQuery: '',
    };
  },

  computed: {
    ...mapGetters({
      agentList: 'agents/getUnmaskedAgents',
      agentsTotal: 'agents/getAgentsTotal',
    }),
    maxPage() {
      return Math.ceil(this.agentsTotal / 20);
    },
  },

  mounted() {
    this.fetchInitialAgents();
    this.$nextTick(() => {
      const listWrapper = this.multiselect.$el.querySelector(
        '.multiselect__content-wrapper'
      );
      if (listWrapper) {
        listWrapper.addEventListener('scroll', this.onScroll);
      }
    });
  },

  methods: {
    fetchInitialAgents() {
      this.$store
        .dispatch('agents/getUnmasked', {
          page: 1,
          query: this.searchQuery,
        })
        .then(() => {
          this.setAccumulativeAgent(this.agentList);
        });
    },
    async addAgents() {
      this.isCreating = true;
      const inboxId = this.$route.params.inbox_id;
      const selectedAgents = this.selectedAgents.map(x => x.id);

      try {
        await InboxMembersAPI.update({ inboxId, agentList: selectedAgents });
        this.$router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: this.$route.params.inbox_id,
          },
        });
      } catch (error) {
        bus.emit('newToastMessage', error.message);
      }
      this.isCreating = false;
    },
    onScroll(event) {
      const el = event.target;
      // infinite scroll if we are at the bottom of the list and there are more pages to load
      if (
        el.scrollHeight - el.scrollTop === el.clientHeight &&
        this.currentPage < this.maxPage
      ) {
        this.$store
          .dispatch('agents/getUnmasked', {
            page: this.currentPage + 1,
            query: this.searchQuery,
          })
          .then(() => {
            this.setAccumulativeAgent(this.agentList);
          });
        this.currentPage += 1;
      }
    },
    setAccumulativeAgent(list) {
      this.accumulativeAgentList = this.accumulativeAgentList.concat(list);
    },
    handleSearch(query) {
      this.page = 1;
      this.$store
        .dispatch('agents/getUnmasked', {
          page: this.page,
          query: query,
        })
        .then(() => {
          this.accumulativeAgentList = this.agentList;
          this.currentPage = 1;
        });
      this.searchQuery = query;
    },
  },
};
</script>
