<template>
  <div class="content">
    <resizable-text-area
      v-model="message"
      class="input"
      data-test-id="copilot_bot_input"
      :placeholder="messagePlaceHolder"
      :min-height="4"
      :disabled="!selectedAssistFlow.name"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div class="footer">
      <woot-button
        size="small"
        :is-disabled="isSendDisabled"
        :data-test-id="'copilot_bot_send_button'"
        @click="onSend"
      >
        {{ $t('CONVERSATION.REPLYBOX.SEND') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import ResizableTextArea from 'shared/components/ResizableTextArea.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { isEnter, hasPressedShift } from 'shared/helpers/KeyboardHelpers';

export default {
  components: { ResizableTextArea },
  props: {
    selectedAssistFlow: {
      type: Object,
      default: () => {},
    },
    sessionId: {
      type: String,
      default: '',
    },
  },
  emits: ['sendMessage'],
  data() {
    return {
      message: '',
      messagePlaceHolder: 'Write something',
      assistClient: {},
      isFocused: false,
    };
  },
  computed: {
    isSendDisabled() {
      return !this.message || !this.selectedAssistFlow.name;
    },
  },
  created() {
    bus.on(BUS_EVENTS.ADD_MESSAGE_TO_COPILOT_BOT_INPUT, message => {
      this.addMessageToInput(message);
    });
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyEvents);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    onSend() {
      this.$emit('sendMessage', this.message);
      this.message = '';
    },
    addMessageToInput(message) {
      this.message = message;
    },
    handleKeyEvents(e) {
      if (isEnter(e)) {
        // Prevent empty enter spaces
        if (!this.message) {
          e.preventDefault();
        }
        const shouldSendMessage =
          !hasPressedShift(e) && this.isFocused && this.message;

        if (shouldSendMessage) {
          e.preventDefault();
          this.onSend();
        }
      }
    },
    onBlur() {
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border-left: 1px solid var(--color-border);
  height: 105px;
}

.input {
  box-shadow: none;
  border-color: transparent;
  margin: 0;
  background: transparent;
  max-height: 4.8rem;
  min-height: 4.8rem;
  padding: 16px 0 0 16px;
  resize: none;
  border-top: 1px solid var(--color-border);
}

.footer {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  padding: 15px 15px 0 0;
}
</style>
