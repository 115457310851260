<template>
  <div class="column content-box">
    <!-- List Agents -->
    <div class="row">
      <div class="flex-auto max-width-large-8 columns with-right-space">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('AGENT_MGMT.LOADING')"
        />
        <div v-else>
          <div class="search-bar-container">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="search"
              class="search-bar"
              @keyup.enter="handleSearchAgents"
            />
            <woot-button
              variant="clear"
              color-scheme="secondary"
              icon="search-circle"
              size="medium"
              @click="handleSearchAgents"
            />
            <woot-button
              v-if="searchQuery"
              variant="clear"
              color-scheme="secondary"
              icon="dismiss-circle"
              size="medium"
              @click="removeQuery"
            />
          </div>
          <p v-if="!agentsList.length">
            {{ $t('AGENT_MGMT.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <tbody>
              <tr v-for="agent in agentsList" :key="agent.id">
                <!-- Gravtar Image -->
                <td>
                  <WootThumbnail
                    :src="agent.thumbnail"
                    class="columns"
                    :username="agent.name"
                    size="40px"
                    :status="agent.availability_status"
                  />
                </td>
                <!-- Agent Name + Email -->
                <td>
                  <span class="agent-name">
                    {{ agent.name }}
                  </span>
                  <span>{{ agent.email }}</span>
                </td>
                <!-- Skills -->
                <div
                  :data-test-id="`agent-skills-${agent.id}`"
                  @click="openAgentSkillsModal(agent)"
                >
                  <td class="skills">
                    <span class="agent-name">
                      {{ $t('AGENT_MGMT.SKILL_LIST') }}
                    </span>
                    <span v-for="skill in agent.skills" :key="skill.id">
                      {{ skill.name }}
                    </span>
                  </td>
                </div>
                <!-- Agent Role + Verification Status -->
                <td>
                  <span class="agent-name">
                    {{
                      agent.roles
                        .map(role => {
                          return $t(
                            `AGENT_MGMT.AGENT_TYPES.${role.toUpperCase()}`
                          );
                        })
                        .join(', ')
                    }}
                  </span>
                  <span v-if="agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFIED') }}
                  </span>
                  <span v-if="!agent.confirmed">
                    {{ $t('AGENT_MGMT.LIST.VERIFICATION_PENDING') }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="agents-pagination small-12 columns">
            <table-footer
              :current-page="page"
              :total-count="agentsTotal"
              :page-size="20"
              @page-change="onPageNumberChange"
            />
          </div>
          <woot-modal
            :show="showAgentSkillsModal"
            :on-close="closeAgentSkillsModal"
            size="medium"
          >
            <agent-skills
              :on-close="closeAgentSkillsModal"
              :agent-id="selectedAgent.id"
              :agent-skills="selectedAgent.skills"
            />
          </woot-modal>
        </div>
      </div>
      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName({
              str: $t('AGENT_MGMT.SIDEBAR_TXT'),
              installationName: globalConfig.installationName,
            })
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import AgentSkills from './AgentSkills.vue';
import WootUIKit from 'components';
import WootButton from '../../../../components/ui/WootButton.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';

export default {
  name: 'AgentHome',
  components: {
    WootLoadingState: WootUIKit.LoadingState,
    WootModal: WootUIKit.Modal,
    WootThumbnail: WootUIKit.Thumbnail,
    AgentSkills,
    TableFooter,
    WootButton,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      page: 1,
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      showSkillPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      showAgentSkillsModal: false,
      selectedAgent: {},
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      agentsList: 'agents/getUnmaskedAgents',
      agentsTotal: 'agents/getAgentsTotal',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    this.$store.dispatch('agents/getUnmasked', {
      page: this.page,
      query: this.searchQuery,
    });
    this.$store.dispatch('skills/get');
  },
  methods: {
    openAgentSkillsModal(agent) {
      this.selectedAgent = agent;
      this.showAgentSkillsModal = true;
    },
    closeAgentSkillsModal() {
      this.selectedAgent = {};
      this.showAgentSkillsModal = false;
    },
    onPageNumberChange(pageIndex) {
      this.page = pageIndex;
      this.$store.dispatch('agents/getUnmasked', {
        page: pageIndex,
        query: this.searchQuery,
      });
    },
    handleSearchAgents(key) {
      this.page = 1;
      this.$store.dispatch('agents/getUnmasked', {
        page: this.page,
        query: this.searchQuery,
      });
    },
    removeQuery() {
      this.searchQuery = '';
      this.page = 1;
      this.$store.dispatch('agents/getUnmasked', {
        page: this.page,
        query: this.searchQuery,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.skills {
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
}

.flex-auto {
  flex-basis: auto;
}

.max-width-large-8 {
  max-width: 130em;
}

.search-bar {
  width: 30%;
  padding: 0.5em;
  margin-bottom: 1em;
}

.search-bar-container {
  display: flex;
  align-content: center;

  button {
    margin-left: 0.5em;
  }
}
</style>
