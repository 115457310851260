import { createStore } from 'vuex';

import accounts from './modules/accounts';
import agents from './modules/agents';
import attachments from './modules/attachments';
import attributes from './modules/attributes';
import auth from './modules/auth';
import auditlogs from './modules/auditlogs';
import automations from './modules/automations';
import bulkActions from './modules/bulkActions/bulkActions';
import cannedResponse from './modules/cannedResponse';
import skills from './modules/skills';
import contactConversations from './modules/contactConversations';
import contacts from './modules/contacts';
import conversationLabels from './modules/conversationLabels/conversationLabels';
import conversationMetadata from './modules/conversationMetadata';
import conversationPage from './modules/conversationPage/conversationPage';
import conversations from './modules/conversations';
import conversationSearch from './modules/conversationSearch';
import conversationStats from './modules/conversationStats';
import conversationTypingStatus from './modules/conversationTypingStatus';
import customViews from './modules/customViews';
import dashboardApps from './modules/dashboardApps';
import draftStore from 'dashboard/store/modules/drafts/draftStore';
import globalConfig from 'shared/store/globalConfig';
import inboxAssignableAgents from './modules/inboxAssignableAgents/inboxAssignableAgents';
import inboxes from './modules/inboxes';
import inboxMembers from './modules/inboxMembers';
import inboxCopilotBots from './modules/inboxCopilotBots';
import labels from './modules/labels/labels';
import notifications from './modules/notifications';
import reports from './modules/reports';
import teamMembers from './modules/teamMembers';
import teams from './modules/teams';
import userNotificationSettings from './modules/userNotificationSettings';
import ui from './modules/ui';
import webhooks from './modules/webhooks';
import {
  createPlugin as createWSPlugin,
  storeModule as wsStoreModule,
  NAMESPACE as WS_NAMESPACE,
} from './plugins/websockets';

export default createStore({
  plugins: [createWSPlugin()],
  modules: {
    [WS_NAMESPACE]: wsStoreModule,
    accounts,
    agents,
    attachments,
    attributes,
    auth,
    automations,
    auditlogs,
    bulkActions,
    cannedResponse,
    skills,
    contactConversations,
    contacts,
    conversationLabels,
    conversationMetadata,
    conversationPage,
    conversations,
    conversationSearch,
    conversationStats,
    conversationTypingStatus,
    customViews,
    dashboardApps,
    draftStore,
    globalConfig,
    inboxAssignableAgents,
    inboxCopilotBots,
    inboxes,
    inboxMembers,
    labels,
    notifications,
    reports,
    teamMembers,
    teams,
    ui,
    userNotificationSettings,
    webhooks,
  },
});
