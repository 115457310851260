<template>
  <woot-modal :show="show" :on-close="onClose">
    <div class="content">
      <img :src="imageUrl" />
    </div>
  </woot-modal>
</template>

<script>
import WootModal from 'components/Modal.vue';

export default {
  components: { WootModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.content {
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  padding: 40px;
}
</style>
