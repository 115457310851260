export default {
  computed: {
    getLabelTitleErrorMessage() {
      let errorMessage = '';
      if (!this.v$.title.$error) {
        return errorMessage;
      }
      if (this.v$.title.required.$invalid) {
        errorMessage = this.$t('LABEL_MGMT.FORM.NAME.REQUIRED_ERROR');
      } else if (this.v$.title.minLength.$invalid) {
        errorMessage = this.$t('LABEL_MGMT.FORM.NAME.MINIMUM_LENGTH_ERROR');
      } else if (this.v$.title.validNameIsSafe.$invalid) {
        errorMessage = this.$t('LABEL_MGMT.FORM.NAME.VALID_ERROR');
      }
      return errorMessage;
    },
  },
};
