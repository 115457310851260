<template>
  <modal :show="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row" @submit.prevent="updateSkill()">
        <div class="medium-12 columns">
          <label :class="{ error: v$.name.$error }">
            {{ $t('SKILLS_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              data-test-id="skill-edit-name-input"
              :placeholder="$t('SKILLS_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="v$.name.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label>
            {{ $t('SKILLS_MGMT.ADD.FORM.TYPE.LABEL') }}
            <select v-model="type" data-test-id="skill-edit-type-select">
              <option
                v-for="option in options"
                :key="option.key"
                :value="option.value"
              >
                {{ $t(option.label) }}
              </option>
            </select>
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              data-test-id="skill-edit-submit-button"
              :disabled="v$.name.$invalid || editSkill.showLoading"
              :button-text="$t('SKILLS_MGMT.ADD.FORM.SUBMIT')"
              :loading="editSkill.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('SKILLS_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from '@vuelidate/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../../../constants';
import useVuelidate from '@vuelidate/core';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin],
  props: {
    id: { type: Number, default: null },
    editType: { type: String, default: '' },
    editName: { type: String, default: '' },
    onClose: { type: Function, default: () => {} },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      editSkill: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      name: this.editName,
      type: this.editType,
      show: true,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    type: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('SKILLS_MGMT.EDIT.TITLE')} - ${this.editName}`;
    },
  },
  methods: {
    showAlert() {
      bus.emit(
        'newToastMessage',
        this.editSkill.message,
        this.editSkill.action
      );
    },
    resetForm() {
      this.name = '';
      this.type = '';
      this.v$.name.$reset();
      this.v$.type.$reset();
    },
    updateSkill() {
      // Show loading on button
      this.editSkill.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('skills/updateSkill', {
          id: this.id,
          name: this.name,
          skill_type: this.type,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editSkill.showLoading = false;
          this.editSkill.message = this.$t(
            'SKILLS_MGMT.EDIT.API.SUCCESS_MESSAGE'
          );
          this.editSkill.action = {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          };
          this.showAlert();
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(error => {
          this.editSkill.showLoading = false;
          const errorMessage =
            error.message === 'Name has already been taken'
              ? this.$t('SKILLS_MGMT.EDIT.API.ERROR_MESSAGE_NAME_TAKEN')
              : this.$t('SKILLS_MGMT.EDIT.API.ERROR_MESSAGE');

          this.editSkill.message = errorMessage;
          this.editSkill.action = {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          };

          this.showAlert();
        });
    },
  },
};
</script>
