import types from '../../mutation-types';
import {
  ContactFilter,
  ContactStoreMeta,
  ContactStoreState,
} from './types/contactStore.types';
import { UiFlags } from '../../types/ui.types';
import { Contact } from './types/contact.types';

export const mutations = {
  [types.SET_CONTACT_UI_FLAG]($state: ContactStoreState, data: UiFlags) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.CLEAR_CONTACTS]: ($state: ContactStoreState) => {
    $state.records = {};
    $state.sortOrder = [];
  },

  [types.SET_CONTACT_META]: (
    $state: ContactStoreState,
    data: ContactStoreMeta
  ) => {
    const { count, current_page: currentPage } = data;
    $state.meta = {
      count,
      currentPage: currentPage || 1,
    };
  },

  [types.SET_CONTACTS]: ($state: ContactStoreState, data: Contact[]) => {
    $state.sortOrder = data.map(contact => {
      $state.records[contact.id] = {
        ...($state.records[contact.id] || {}),
        ...contact,
      };
      return contact.id;
    });
  },

  [types.SET_CONTACT_ITEM]: ($state: ContactStoreState, data: Contact) => {
    $state.records[data.id] = {
      ...($state.records[data.id] || {}),
      ...data,
    };

    if (!$state.sortOrder.includes(data.id)) {
      $state.sortOrder.push(data.id);
    }
  },

  [types.EDIT_CONTACT]: ($state: ContactStoreState, data: Contact) => {
    $state.records[data.id] = data;
  },

  [types.DELETE_CONTACT]: ($state: ContactStoreState, id: number) => {
    const index = $state.sortOrder.findIndex(item => item === id);
    $state.sortOrder.splice(index, 1);
    delete $state.records[id];
  },

  [types.UPDATE_CONTACTS_PRESENCE]: (
    $state: ContactStoreState,
    data: Record<number, string>
  ) => {
    Object.values($state.records).forEach(element => {
      const availabilityStatus = data[element.id];
      if (availabilityStatus) {
        $state.records[element.id].availability_status = availabilityStatus;
      } else {
        delete $state.records[element.id].availability_status;
      }
    });
  },

  [types.SET_CONTACT_FILTERS](
    $state: ContactStoreState,
    data: ContactFilter[]
  ) {
    $state.appliedFilters = data;
  },

  [types.CLEAR_CONTACT_FILTERS]($state: ContactStoreState) {
    $state.appliedFilters = [];
  },
};
