import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { toRaw } from 'vue';
import Cookies from 'js-cookie';

import {
  getActiveAccountId,
  getOrganisationId,
  setActiveAccountId,
} from '../api/activeAccountId';
import store from '../store';
import loginRoutes from './login/login.routes';
import dashboardRoutes from './dashboard/dashboard.routes';
import { frontendURL } from '../helper/URLHelper';
import {
  clearBrowserSessionCookies,
  clearCookiesOnLogout,
} from '../store/utils/api';

const getUserRoles = (user, accountId) => {
  const currentAccount = (user?.accounts ?? []).find(
    account => account.id === accountId
  );
  return currentAccount ? currentAccount.roles : null;
};

function createRouter() {
  const login = loginRoutes;
  const dashboard = dashboardRoutes(store);

  const routes = [...login.routes, ...dashboard.routes];

  window.roleWiseRoutes = {
    agent: [],
    administrator: [],
    supervisor: [],
  };

  // generateRoleWiseRoute - updates window object with agent/admin route
  const generateRoleWiseRoute = route => {
    route.forEach(element => {
      if (element.children) {
        generateRoleWiseRoute(element.children);
      }
      if (element.roles) {
        element.roles.forEach(roleEl => {
          window.roleWiseRoutes[roleEl].push(element.name);
        });
      }
    });
  };
  // Create a object of routes
  // accessible by each role.
  // returns an object with roles as keys and routeArr as values
  generateRoleWiseRoute(routes);

  return createVueRouter({ history: createWebHistory(), routes });
}

const unProtectedRoutes = [
  'login',
  'auth_signup',
  'auth_reset_password',
  'login_oauth',
];

const authIgnoreRoutes = [
  'auth_confirmation',
  'pushBack',
  'auth_password_edit',
];

function routeIsAccessibleFor(route, roles = []) {
  const accessibleRoutes = Object.entries(window.roleWiseRoutes).reduce(
    (acc, [role, roleRoutes]) => {
      if (roles.includes(role)) {
        roleRoutes.forEach(currentRoute => acc.add(currentRoute));
      }
      return acc;
    },
    new Set()
  );

  return accessibleRoutes.has(route);
}

const routeValidators = [
  {
    protected: false,
    loggedIn: true,
    handler: (to, getters) => {
      const { organisationId } = to.query ?? {};
      const existingOrganisationId = getOrganisationId();

      if (organisationId) {
        if (existingOrganisationId !== organisationId) {
          clearCookiesOnLogout(organisationId);
          return null;
        }

        setActiveAccountId({ store: { getters }, organisationId });
      }

      const activeAccountId = getActiveAccountId({ store: { getters } });
      return `accounts/${activeAccountId}/dashboard`;
    },
  },
  {
    protected: true,
    loggedIn: false,
    handler: () => 'login',
  },
  {
    protected: true,
    loggedIn: false,
    handler: () => 'login_oauth',
  },
  {
    protected: true,
    loggedIn: true,
    handler: (to, getters) => {
      const user = toRaw(getters.getCurrentUser);

      const userRoles = getUserRoles(user, Number(to.params.accountId));

      const isAccessible = routeIsAccessibleFor(to.name, userRoles);
      return isAccessible ? null : `accounts/${to.params.accountId}/dashboard`;
    },
  },
  {
    protected: false,
    loggedIn: false,
    handler: () => null,
  },
];

export const validateAuthenticateRoutePermission = (
  to,
  from,
  next,
  { getters }
) => {
  const isLoggedIn = getters.isLoggedIn;
  const isProtectedRoute = !unProtectedRoutes.includes(to.name);
  const strategy = routeValidators.find(
    validator =>
      validator.protected === isProtectedRoute &&
      validator.loggedIn === isLoggedIn
  );
  const nextRoute = strategy.handler(to, getters);

  if (nextRoute === 'login') {
    Cookies.set('redirect_url', to.fullPath);
  }

  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

const validateSSOLoginParams = to => {
  const isLoginRoute = to.name === 'login';
  const { email, sso_auth_token: ssoAuthToken } = to.query || {};
  const hasValidSSOParams = email && ssoAuthToken;
  return isLoginRoute && hasValidSSOParams;
};

export const validateRouteAccess = (to, from, next, { getters }) => {
  // Disable navigation to signup page if signups are disabled
  // Signup route has an attribute (requireSignupEnabled)
  // defined in it's route definition
  if (
    window.chatwootConfig.signupEnabled !== 'true' &&
    to.meta &&
    to.meta.requireSignupEnabled
  ) {
    return next(frontendURL('login'));
  }

  // For routes which doesn't care about authentication, skip validation
  if (authIgnoreRoutes.includes(to.name)) {
    return next();
  }

  return validateAuthenticateRoutePermission(to, from, next, { getters });
};

const router = createRouter();

export const initalizeRouter = () => {
  const userAuthentication = store.dispatch('setUser');

  router.beforeEach((to, from, next) => {
    if (validateSSOLoginParams(to)) {
      clearBrowserSessionCookies();
      next();
      return;
    }

    userAuthentication.then(() => {
      if (!to.name) {
        const { isLoggedIn, getCurrentUser: user } = store.getters;
        if (isLoggedIn) {
          const activeAccountId = getActiveAccountId({ store, user });
          return next(frontendURL(`accounts/${activeAccountId}/dashboard`));
        }

        return next('/app/login');
      }
      return validateRouteAccess(to, from, next, store);
    });
  });
};

export default router;
