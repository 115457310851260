<template>
  <div class="date-picker">
    <date-picker
      :value="value"
      :range="true"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :disabled-date="disabledBeforeAYearAndAfterToday"
      @change="handleChange"
    />
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import { validateDateRange } from '../../../shared/helpers/DateHelper';

export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change', 'update:value'],
  methods: {
    handleChange(value) {
      this.$emit('change', value);
      this.$emit('update:value', value);
    },
    disabledBeforeAYearAndAfterToday(date) {
      return validateDateRange(date);
    },
  },
};
</script>
