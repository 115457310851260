<template>
  <div>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
    >
      <multi-select
        ref="multiselect"
        v-model="selectedAgents"
        :options="agentList"
        data-test-id="inbox_collaborators_select"
        track-by="id"
        label="name"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="true"
        placeholder="Pick some"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        @select="v$.selectedAgents.$touch"
      />

      <woot-submit-button
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        :loading="isAgentListUpdating"
        @click="updateAgents"
      />
    </settings-section>

    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT_SUB_TEXT')"
    >
      <label class="medium-9 columns settings-item">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT') }}
        <select
          v-model="enableAutoAssignment"
          data-test-id="inbox_settings_auto_assignment"
          @change="updateInbox"
        >
          <option :value="true">
            {{ $t('INBOX_MGMT.EDIT.AGENT_REASSIGNMENT.ENABLED') }}
          </option>
          <option :value="false">
            {{ $t('INBOX_MGMT.EDIT.AGENT_REASSIGNMENT.DISABLED') }}
          </option>
        </select>
        <p class="help-text small">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT') }}
        </p>

        <input
          id="considerConvCount"
          v-model="enableConversationCount"
          type="checkbox"
          data-test-id="inbox_settings_consider_conv_count"
          @change="updateInbox"
        />
        <label for="considerConvCount">{{
          $t('INBOX_MGMT.SETTINGS_POPUP.CONSIDER_CONVERSATION_COUNT')
        }}</label>
        <br />

        <input
          id="considerConvSkills"
          v-model="enableConversationSkills"
          type="checkbox"
          data-test-id="inbox_settings_consider_conv_skills"
          @change="updateInbox"
        />
        <label for="considerConvSkills">{{
          $t('INBOX_MGMT.SETTINGS_POPUP.CONSIDER_CONVERSATION_SKILLS')
        }}</label>
        <br />

        <input
          id="considerConvLang"
          v-model="enableConversationLanguages"
          type="checkbox"
          data-test-id="inbox_settings_consider_conv_lang"
          @change="updateInbox"
        />
        <label for="considerConvLang">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.CONSIDER_CONVERSATION_LANGUAGES') }}
        </label>
        <br />

        <input
          id="considerConvPrio"
          v-model="enableConversationPriority"
          type="checkbox"
          data-test-id="inbox_settings_consider_conv_prio"
          @change="updateInbox"
        />
        <label for="considerConvPrio">{{
          $t('INBOX_MGMT.SETTINGS_POPUP.CONSIDER_CONVERSATION_PRIORITY')
        }}</label>
        <br />
        <br />
        <p class="help-text small">
          {{ helpText }}
        </p>
        <br />
      </label>

      <label class="medium-9 columns settings-item">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.AGENT_REASSIGNMENT') }}
        <select
          v-model="enableReautoassignment"
          data-test-id="allow_agent_reassignment_inbox"
          @change="updateInbox"
        >
          <option :value="true">
            {{ $t('INBOX_MGMT.EDIT.AGENT_REASSIGNMENT.ENABLED') }}
          </option>
          <option :value="false">
            {{ $t('INBOX_MGMT.EDIT.AGENT_REASSIGNMENT.DISABLED') }}
          </option>
        </select>
        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.AGENT_REASSIGNMENT_SUB_TEXT') }}
        </p>
      </label>

      <label class="medium-9 columns settings-item">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.ALL_CONVERSATIONS') }}
        <select
          v-model="enableShowAllConversations"
          data-test-id="allow_all_conversations_inbox"
          @change="updateInbox"
        >
          <option :value="true">
            {{ $t('INBOX_MGMT.EDIT.ALL_CONVERSATIONS.ENABLED') }}
          </option>
          <option :value="false">
            {{ $t('INBOX_MGMT.EDIT.ALL_CONVERSATIONS.DISABLED') }}
          </option>
        </select>
        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ALL_CONVERSATIONS_SUB_TEXT') }}
        </p>
      </label>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import wootConstants from '../../../../../constants';
import useVuelidate from '@vuelidate/core';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      selectedAgents: [],
      isAgentListUpdating: false,
      enableAutoAssignment: false,
      enableShowAllConversations: false,
      enableReautoassignment: true,
      enableConversationCount: false,
      enableConversationSkills: false,
      enableConversationLanguages: false,
      enableConversationPriority: false,
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getUnmaskedAgents',
    }),
    helpText() {
      return this.$t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_HELP_TEXT');
    },
  },
  async mounted() {
    await this.fetchInitialAgents();
    await this.setDefaults();
  },
  methods: {
    async fetchInitialAgents() {
      await this.$store.dispatch('agents/getUnmasked', { minified: true });
    },
    async setDefaults() {
      this.enableAutoAssignment = this.inbox.enable_auto_assignment;
      this.enableReautoassignment = this.inbox.enable_auto_reassignment;
      this.enableShowAllConversations = this.inbox.conversations_show_all;
      this.enableConversationCount = this.inbox.enable_conversation_count;
      this.enableConversationSkills = this.inbox.enable_skills_routing;
      this.enableConversationLanguages = this.inbox.enable_language_routing;
      this.enableConversationPriority = this.inbox.enable_priority_routing;
      await this.setAttachedAgents();
    },
    async setAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.inbox.id,
        });

        const {
          data: { payload: inboxMembers },
        } = response;
        const inboxMemberIds = inboxMembers.map(member => member.id);

        this.selectedAgents = this.agentList.filter(agent =>
          inboxMemberIds.includes(agent.id)
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      this.isAgentListUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.inbox.id,
          agentList,
        });
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
      this.isAgentListUpdating = false;
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          enable_auto_assignment: this.enableAutoAssignment,
          conversations_show_all: this.enableShowAllConversations,
          enable_auto_reassignment: this.enableReautoassignment,
          enable_conversation_count: this.enableConversationCount,
          enable_skills_routing: this.enableConversationSkills,
          enable_language_routing: this.enableConversationLanguages,
          enable_priority_routing: this.enableConversationPriority,
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
  },
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },
};
</script>
