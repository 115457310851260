<template>
  <textarea
    ref="textarea"
    :data-test-id="dataTestId"
    :disabled="disabled"
    :placeholder="placeholder"
    :value="modelValue"
    class="reply-box-textarea"
    @blur="onBlur"
    @focus="onFocus"
    @input="onInput"
    @keyup="onKeyup"
  />
</template>

<script>
import { createTypingIndicator } from '@/shared/helpers/typingStatus';
const TYPING_INDICATOR_IDLE_TIME = 4000;
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    minHeight: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
  emits: ['typing-off', 'typing-on', 'blur', 'focus', 'update:modelValue'],
  data() {
    return {
      typingIndicator: createTypingIndicator(
        () => {
          this.$emit('typing-on');
        },
        () => {
          this.$emit('typing-off');
        },
        TYPING_INDICATOR_IDLE_TIME
      ),
    };
  },
  updated() {
    this.resizeTextarea();
  },
  methods: {
    resizeTextarea() {
      if (!this.modelValue) {
        this.$el.style.height = `${this.minHeight}rem`;
      } else {
        this.$el.style.height = `${this.$el.scrollHeight - 4}px`;
      }
    },
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.resizeTextarea();
    },
    onKeyup() {
      this.typingIndicator.start();
    },
    onBlur() {
      this.typingIndicator.stop();
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    focus() {
      this.$refs.textarea.focus();
    },
  },
};
</script>
<style scoped>
.reply-box-textarea {
  height: 70px;
}
</style>
