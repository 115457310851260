<template>
  <div class="content">
    <select
      v-model="selected"
      class="flow-select"
      data-test-id="copilot_bot_select"
      :disabled="!hasFlows"
      :class="{ empty: noFlowSelected }"
      @change="setSelectedAssistFlow"
    >
      <option :value="{}" selected disabled>
        {{
          hasFlows
            ? $t('CONVERSATION_SIDEBAR.COPILOT_BOT.SELECT_PLACEHOLDER')
            : $t('CONVERSATION_SIDEBAR.COPILOT_BOT.SELECT_PLACEHOLDER_NO_FLOWS')
        }}
      </option>
      <option
        v-for="(flow, key) in flows"
        :key="key"
        :value="flow"
        :class="{ emptyOption: noFlowSelected }"
        :data-test-id="`bot-${flow.name}`"
      >
        {{ flow.name }}
      </option>
    </select>
    <copilot-bot-context-menu
      :is-open="showContextMenu"
      :menu-position="'left'"
      @toggle="handleContextMenuClick"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { frontendURL } from 'dashboard/helper/URLHelper';
import { isEmptyObject } from 'widget/helpers/utils';
import CopilotBotContextMenu from './CopilotBotContextMenu.vue';

export default {
  components: {
    CopilotBotContextMenu,
  },
  props: {
    flows: {
      type: Array,
      default: () => [],
    },
    selectedAssistFlow: {
      type: Object,
      default: () => {},
    },
    inboxId: {
      type: Number,
      default: null,
    },
  },
  emits: ['resetSession'],
  data() {
    return {
      selected: '',
      showContextMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    settingsPath() {
      return frontendURL(
        `accounts/${this.accountId}/settings/inboxes/${this.inboxId}`
      );
    },
    noFlowSelected() {
      return isEmptyObject(this.selectedAssistFlow);
    },
    hasFlows() {
      return this.flows.length > 0;
    },
  },
  watch: {
    selectedAssistFlow: {
      immediate: true,
      handler() {
        this.setFlow();
      },
    },
  },
  mounted() {
    this.setFlow();
  },
  methods: {
    setSelectedAssistFlow() {
      this.$parent.$emit('setSelectedAssistFlow', this.selected);
    },
    setFlow() {
      this.selected = this.selectedAssistFlow;
    },
    handleContextMenuClick() {
      this.showContextMenu = !this.showContextMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border-bottom: 1px solid var(--color-border);
  border-left: 1px solid var(--color-border);
  justify-content: space-evenly;
  display: flex;
  align-content: center;
  padding: 15px 5px 0;
}
.flow-select {
  width: 80%;
}

.buttonContent {
  margin-top: 3px;
}

.addButton {
  margin-left: 3px;
}

select option[disabled]:first-child {
  display: none;
}

select.empty {
  color: grey;
}
.emptyOption {
  color: black;
}
</style>
