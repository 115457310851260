import types from '../../mutation-types';
import { INotification, INotificationsState } from './types';

export const mutations = {
  [types.SET_NOTIFICATIONS_UI_FLAG](
    $state: INotificationsState,
    data: {
      [x: string]: boolean;
    }
  ) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.CLEAR_NOTIFICATIONS]: ($state: INotificationsState) => {
    $state.records = {};
  },
  [types.SET_NOTIFICATIONS_META]: (
    $state: INotificationsState,
    data: {
      count: number;
      current_page: number;
      unread_count: number;
    }
  ) => {
    const {
      count,
      current_page: currentPage,
      unread_count: unreadCount,
    } = data;
    $state.meta.count = count;
    $state.meta.currentPage = currentPage;
    $state.meta.unreadCount = unreadCount;
  },
  [types.SET_NOTIFICATIONS_UNREAD_COUNT]: (
    $state: INotificationsState,
    count: number
  ) => {
    $state.meta.unreadCount = count < 0 ? 0 : count;
  },
  [types.SET_NOTIFICATIONS]: (
    $state: INotificationsState,
    data: {
      id: number;
    }[]
  ) => {
    let isDataChanged = false;
    const newRecords = { ...$state.records };

    data.forEach(notification => {
      const existingNotification = newRecords[notification.id];
      if (
        !existingNotification ||
        JSON.stringify(existingNotification) !== JSON.stringify(notification)
      ) {
        newRecords[notification.id] = {
          ...(existingNotification || {}),
          ...notification,
        };
        isDataChanged = true;
      }
    });

    if (isDataChanged) {
      $state.records = newRecords;
    }
  },
  [types.UPDATE_NOTIFICATION]: (
    $state: INotificationsState,
    primaryActorId: number
  ) => {
    Object.values($state.records).forEach(item => {
      if (item.primary_actor_id === primaryActorId) {
        $state.records[item.id].read_at = true;
      }
    });
  },
  [types.UPDATE_ALL_NOTIFICATIONS]: ($state: INotificationsState) => {
    Object.values($state.records).forEach(item => {
      $state.records[item.id].read_at = true;
    });
  },

  [types.ADD_NOTIFICATION](
    $state: INotificationsState,
    data: {
      notification: INotification;
      unread_count: number;
      count: number;
    }
  ) {
    const { notification, unread_count: unreadCount, count } = data;
    $state.records[notification.id] = {
      ...($state.records[notification.id] || {}),
      ...notification,
    };
    $state.meta.unreadCount = unreadCount;
    $state.meta.count = count;
  },
};
