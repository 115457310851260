<template>
  <basic-modal
    :show="show"
    :header-title="
      isEditing
        ? $t('INBOX_MGMT.COPILOT_BOT.EDIT_COPILOT_BOT')
        : $t('INBOX_MGMT.COPILOT_BOT.CREATE_COPILOT_BOT')
    "
    :header-content="
      isEditing
        ? $t('INBOX_MGMT.COPILOT_BOT.EDIT_COPILOT_BOT_HEADER')
        : $t('INBOX_MGMT.COPILOT_BOT.CREATE_COPILOT_BOT_HEADER')
    "
    :primary-button-text="
      isEditing
        ? $t('INBOX_MGMT.COPILOT_BOT.UPDATE_COPILOT_BOT_BUTTON')
        : $t('INBOX_MGMT.COPILOT_BOT.ADD_COPILOT_BOT_BUTTON')
    "
    :secondary-button-text="$t('INBOX_MGMT.COPILOT_BOT.CANCEL_BUTTON')"
    @cancel="closeCopilotBotConfiguration"
    @submit="onSubmit"
  >
    <div data-test-id="copilot_bot_configuration">
      <label :class="{ error: v$?.name.$error }">
        {{ $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_NAME') }}
        <woot-input
          v-model.trim="name"
          class="medium-12 columns"
          data-test-id="copilot_bot_name_input"
          @blur="v$.name.$touch"
        />
        <span v-if="v$.name.$error && !v$.name.required" class="message">{{
          $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_NAME_ERROR')
        }}</span>
        <span
          v-if="v$.name.$error && !v$.name.isLessThan200Characters"
          class="message"
        >
          {{ $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_NAME_MAX_ERROR') }}
        </span>
      </label>
    </div>

    <div>
      <label :class="{ error: v$.endpointUrl.$error }">
        {{ $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_ENDPOINT') }}
        <span class="info-text">
          {{ infoText }}
        </span>
        <woot-input
          v-model.trim="endpointUrl"
          class="medium-12 columns"
          data-test-id="copilot_bot_endpoint_url"
          @blur="v$.endpointUrl.$touch"
        />
        <span
          v-if="
            v$.endpointUrl.$error &&
            v$.$errors.some(error => error.$validator === 'shouldBeEndpointUrl')
          "
          class="message"
        >
          {{ $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_ENDPOINT_ERROR') }}
        </span>
        <span
          v-if="
            v$.endpointUrl.$error &&
            v$.$errors.some(
              error => error.$validator === 'isLessThan200Characters'
            )
          "
          class="message"
        >
          {{ $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_NAME_MAX_ERROR') }}
        </span>
      </label>
    </div>
  </basic-modal>
</template>

<script>
import BasicModal from '../../../../../components/widgets/modal/BasicModal.vue';
import { required } from '@vuelidate/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { updateSocketConnection } from './helpers/updateSocketConnection.js';
import wootConstants from '../../../../../constants';
import useVuelidate from '@vuelidate/core';

const shouldBeEndpointUrl = (value = '') =>
  value.startsWith('http:') || value.startsWith('https:');

const isLessThan200Characters = (value = '') => value.length < 200;

export default {
  components: { BasicModal },
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    copilotBot: {
      type: Object,
      default() {
        return { endpoint_url: '', id: '', name: '' };
      },
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closePopup'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      name: '',
      endpointUrl: '',
      id: '',
    };
  },
  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    infoText() {
      return this.$t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_ENDPOINT_TOOLTIP');
    },
  },
  validations: {
    name: { required, isLessThan200Characters },
    endpointUrl: { shouldBeEndpointUrl, required, isLessThan200Characters },
  },
  mounted() {
    if (this.isEditing) {
      this.name = this.copilotBot.name;
      this.endpointUrl = this.copilotBot.endpoint_url;
      this.id = this.copilotBot.id;
    }
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      if (this.isEditing) {
        this.editBot();
      } else {
        this.addBot();
      }
    },
    async addBot() {
      const copilotBot = { name: this.name, endpoint_url: this.endpointUrl };
      await this.$store.dispatch('inboxCopilotBots/addCopilotBot', {
        inboxId: this.currentInboxId,
        copilotBot: copilotBot,
      });
      this.closeCopilotBotConfiguration();
    },
    async editBot() {
      const copilotBot = {
        name: this.name,
        endpoint_url: this.endpointUrl,
        id: this.id,
      };
      await this.$store.dispatch('inboxCopilotBots/editCopilotBot', {
        inboxId: this.currentInboxId,
        copilotBot: copilotBot,
      });
      this.closeCopilotBotConfiguration();
      updateSocketConnection(this.id, this.endpointUrl);
      this.showAlert(
        this.$t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_EDIT_SUCCESS_MESSAGE'),
        {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        }
      );
    },
    closeCopilotBotConfiguration() {
      this.$emit('closePopup');
    },
  },
};
</script>
<style scoped>
.info-text {
  opacity: 0.5;
}
</style>
