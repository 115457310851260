<template>
  <div class="column content-box">
    <div class="button--fixed-right-top">
      <dashboard-button
        :class="'button-add-canned-response'"
        color-scheme="success"
        icon="add-circle"
        :data-test-id="'add_canned_response'"
        @click="openAddPopup()"
      >
        {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
      </dashboard-button>
      <dashboard-button
        color-scheme="success"
        icon="add-circle"
        :data-test-id="'import_canned_response'"
        @click="openImportPopup()"
      >
        {{ $t('CANNED_MGMT.HEADER_BTN_TXT_IMPORT') }}
      </dashboard-button>
    </div>
    <!-- List Canned Response -->
    <div class="row">
      <div class="flex-auto max-width-large-8 columns with-right-space">
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="no-items-error-message"
        >
          {{ $t('CANNED_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('CANNED_MGMT.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table"
        >
          <thead>
            <!-- Header -->
            <th
              v-for="thHeader in tm('CANNED_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(cannedItem, index) in records"
              :key="cannedItem.short_code"
            >
              <!-- Short Code  -->
              <td data-test-id="canned_response_short_code" class="short-code">
                {{ cannedItem.short_code }}
              </td>
              <!-- Content -->
              <td data-test-id="canned_response_content">
                {{ cannedItem.content }}
              </td>
              <!-- Level -->
              <td data-test-id="canned_response_level">
                {{
                  $t(
                    `CANNED_MGMT.ADD.FORM.LEVEL.OPTIONS.${cannedItem.owner_type.toUpperCase()}`
                  )
                }}
              </td>
              <!-- Action Buttons -->
              <td class="button-wrapper">
                <dashboard-button
                  v-if="
                    !isAgent || (isAgent && cannedItem.owner_type === 'User')
                  "
                  v-tooltip.top="$t('CANNED_MGMT.EDIT.BUTTON_TEXT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  icon="edit"
                  :data-test-id="
                    'edit_canned_response_' + cannedItem.short_code
                  "
                  @click="openEditPopup(cannedItem)"
                />
                <dashboard-button
                  v-if="
                    !isAgent || (isAgent && cannedItem.owner_type === 'User')
                  "
                  v-tooltip.top="$t('CANNED_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[cannedItem.id]"
                  :data-test-id="
                    'delete_canned_response_' + cannedItem.short_code
                  "
                  @click="openDeletePopup(cannedItem, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('CANNED_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show="showAddPopup" :on-close="hideAddPopup">
      <add-canned
        :on-close="hideAddPopup"
        data-test-id="add_canned_response_modal"
      />
    </woot-modal>
    <woot-modal :show="showImportPopup" :on-close="hideImportPopup">
      <import-canned
        :on-close="hideImportPopup"
        data-test-id="import_canned_response_modal"
      />
    </woot-modal>

    <!-- Edit Canned Response -->
    <woot-modal :show="showEditPopup" :on-close="hideEditPopup">
      <edit-canned
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edshort-code="selectedResponse.short_code"
        :edcontent="selectedResponse.content"
        :edowner-type="selectedResponse.owner_type"
        :edowner-id="selectedResponse.owner_id"
        :on-close="hideEditPopup"
        data-test-id="edit_canned_response_modal"
      />
    </woot-modal>

    <!-- Delete Canned Response -->
    <woot-delete-modal
      :show="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddCanned from './AddCanned.vue';
import ImportCanned from './ImportCanned.vue';
import EditCanned from './EditCanned.vue';
import wootConstants from '../../../../constants';
import WootUIKit from 'components';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'CannedHome',
  components: {
    DashboardButton: WootUIKit.Button,
    WootModal: WootUIKit.Modal,
    WootDeleteModal: WootUIKit.DeleteModal,
    WootLoadingState: WootUIKit.LoadingState,
    AddCanned,
    EditCanned,
    ImportCanned,
  },
  setup() {
    const { tm } = useI18n();
    return { tm, v$: useVuelidate() };
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showImportPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      records: 'getCannedResponses',
      uiFlags: 'getUIFlags',
      currentRoles: 'getCurrentRoles',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedResponse.short_code
      } ?`;
    },
    isAgent() {
      return (
        this.currentRoles.includes('agent') &&
        !this.currentRoles.includes('administrator') &&
        !this.currentRoles.includes('supervisor')
      );
    },
  },
  mounted() {
    // Fetch API Call
    this.$store.dispatch('getCannedResponse');
  },
  methods: {
    showAlert(message, action) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.emit('newToastMessage', message, action);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    openImportPopup() {
      this.showImportPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    hideImportPopup() {
      this.showImportPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.$store
        .dispatch('deleteCannedResponse', id)
        .then(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
        })
        .catch(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        });
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
}
.button-add-canned-response {
  margin-right: 1rem;
}
.flex-auto {
  flex-basis: auto;
}
.max-width-large-8 {
  max-width: 130em;
}
</style>
