<template>
  <woot-modal :show="show" :on-close="onClose">
    <div class="content" data-test-id="audit-logs-modal">
      <h4>
        <span>{{ generateLogText(auditLogItem) }}</span>
      </h4>
      <div class="table">
        <div class="container" data-test-id="audit-logs-user">
          <p v-if="auditLogItem.action === 'create'" class="key">
            {{ $t('AUDIT_LOGS.MODAL.CREATED_BY') }}
          </p>
          <p v-else-if="auditLogItem.action === 'update'" class="key">
            {{ $t('AUDIT_LOGS.MODAL.UPDATED_BY') }}
          </p>
          <p v-else class="key">
            {{ $t('AUDIT_LOGS.MODAL.DELETED_BY') }}
          </p>
          <div class="sub-container">
            <p class="value">{{ auditLogItem.username }}</p>
          </div>
        </div>
        <div class="container" data-test-id="audit-logs-time">
          <p v-if="auditLogItem.action === 'create'" class="key">
            {{ $t('AUDIT_LOGS.MODAL.CREATED_AT') }}
          </p>
          <p v-else-if="auditLogItem.action === 'update'" class="key">
            {{ $t('AUDIT_LOGS.MODAL.UPDATED_AT') }}
          </p>
          <p v-else class="key">
            {{ $t('AUDIT_LOGS.MODAL.DELETED_AT') }}
          </p>
          <div class="sub-container">
            <span class="value time-stamp">{{
              messageTimestamp(auditLogItem.created_at, 'MMM dd, yyyy hh:mm a')
            }}</span>
          </div>
        </div>
        <div class="container" data-test-id="audit-logs-action">
          <p class="key">
            {{ $t('AUDIT_LOGS.MODAL.ACTION') }}
          </p>
          <div class="sub-container">
            <p class="value">{{ auditLogItem.action }}</p>
          </div>
        </div>
        <div
          v-if="typeof auditLogItem.audited_changes.name === 'string'"
          class="container"
          data-test-id="audit-logs-name"
        >
          <p class="key">
            {{ $t('AUDIT_LOGS.MODAL.NAME') }}
          </p>
          <div class="sub-container">
            <p class="value">{{ auditLogItem.audited_changes.name }}</p>
          </div>
        </div>
        <div class="container" data-test-id="audit-logs-id">
          <p class="key">
            {{ $t('AUDIT_LOGS.MODAL.ID') }}
          </p>
          <div class="sub-container">
            <p class="value">{{ auditLogItem.auditable_id }}</p>
          </div>
        </div>
        <div
          v-if="auditLogItem.auditable_type === 'InboxMember'"
          class="container"
          data-test-id="audit-logs-id"
        >
          <p class="key">
            {{ $t('AUDIT_LOGS.MODAL.MEMBER') }}
          </p>
          <div class="sub-container">
            <p class="value">
              {{ getAgentName(auditLogItem.audited_changes.user_id) }}
            </p>
          </div>
        </div>
        <div
          v-if="auditLogItem.auditable_type === 'InboxMember'"
          class="container"
          data-test-id="audit-logs-id"
        >
          <p class="key">
            {{ $t('AUDIT_LOGS.MODAL.INBOX_ID') }}
          </p>
          <div class="sub-container">
            <p class="value">
              {{ auditLogItem.audited_changes.inbox_id }}
            </p>
          </div>
        </div>
        <div
          v-if="
            auditLogItem.auditable_type === 'Channel::Api' &&
            auditLogItem.action === 'create'
          "
          class="container"
          data-test-id="audit-logs-id"
        >
          <p class="key">
            {{ $t('AUDIT_LOGS.MODAL.WEBHOOK_URL') }}
          </p>
          <div class="sub-container">
            <p class="value">
              {{ auditLogItem.audited_changes.webhook_url }}
            </p>
          </div>
        </div>
        <div v-if="auditLogItem.action === 'update'" class="changes-container">
          <p class="sub-title">
            {{ $t('AUDIT_LOGS.MODAL.CHANGES') }}
          </p>
          <div class="headers">
            <p class="key">
              {{ $t('AUDIT_LOGS.MODAL.PREVIOUS_VALUE') }}
            </p>
            <p class="key">
              {{ $t('AUDIT_LOGS.MODAL.CURRENT_VALUE') }}
            </p>
          </div>
          <div class="changes" data-test-id="audit-logs-changes">
            <div
              v-for="(value, key) in auditLogItem.audited_changes"
              :key="key"
              class="audit-changes"
            >
              <p class="audit-changes-key">
                {{ generateAuditChangesText(key) }}
              </p>
              <div class="values">
                <p class="value change-value before">
                  {{ generateText(key, value[0]) }}
                </p>
                <p class="value change-value after">
                  {{ generateText(key, value[1]) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import {
  AUTOMATION_RULE_EVENTS,
  AUTOMATION_ACTION_TYPES,
  AUTOMATION_CONDITION_TYPES,
  OPERATOR_TYPES,
  QUERY_OPERATOR_TYPES,
} from '../automation/constants';
import WootUIKit from 'components';

export default {
  components: { WootModal: WootUIKit.Modal },
  mixins: [timeMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    auditLogItem: {
      type: Object,
      default: () => {},
    },
    generateLogText: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      agents: 'agents/getAgents',
    }),
  },
  methods: {
    generateAuditChangesText(changes) {
      const logChangesKey = `audit-changes:${changes}`;

      const translationKeys = {
        'audit-changes:actions': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ACTIONS`,
        'audit-changes:name': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.NAME`,
        'audit-changes:description': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.DESCRIPTION`,
        'audit-changes:event_name': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.EVENT_NAME`,
        'audit-changes:conditions': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.CONDITIONS`,
        'audit-changes:enable_auto_assignment': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ENABLE_AUTO_ASSIGNMENT`,
        'audit-changes:conversations_show_all': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.CONVERSATIONS_SHOW_ALL`,
        'audit-changes:enable_conversation_count': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ENABLE_CONVERSATION_COUNT`,
        'audit-changes:enable_skills_routing': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ENABLE_SKILLS_ROUTING`,
        'audit-changes:enable_language_routing': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ENABLE_LANGUAGE_ROUTING`,
        'audit-changes:enable_priority_routing': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ENABLE_PRIORITY_ROUTING`,
        'audit-changes:enable_auto_reassignment': `AUDIT_LOGS.MODAL.AUDIT_CHANGES.ENABLE_AUTO_REASSIGNMENT`,
      };

      return this.$t(translationKeys[logChangesKey]);
    },
    generateText(key, value) {
      if (key === 'name' || key === 'description') {
        return value;
      }
      if (key === 'event_name') {
        const ruleEvent = AUTOMATION_RULE_EVENTS.find(
          event => event.key === value
        );

        return this.$t(
          `AUTOMATION.ADD.FORM.EVENT.${ruleEvent.attributeI18nKey}`
        );
      }
      if (key === 'actions') {
        let actions = [];
        value.forEach((element, index) => {
          const actionType = AUTOMATION_ACTION_TYPES.find(
            action => action.key === value[index].action_name
          );
          const queryOperatorType = QUERY_OPERATOR_TYPES.find(
            operator => operator.key === value[index].query_operator
          );
          actions.push(
            `${this.$t(
              `AUTOMATION.ADD.FORM.ACTIONS.${actionType.attributeI18nKey}`
            )}`
          );
          actions.push(`${value[index].action_params}`);
          actions.push(`\n`);
          if (queryOperatorType) {
            actions.push(
              `${this.$t(
                `FILTER.QUERY_DROPDOWN_LABELS.${queryOperatorType.attributeI18nKey}`
              )}`
            );
            actions.push(`\n`);
          }
        });
        return actions.join('\n');
      }

      if (key === 'conditions') {
        let conditions = [];
        value.forEach((element, index) => {
          const actionType = AUTOMATION_CONDITION_TYPES.find(
            action => action.key === value[index].attribute_key
          );
          const operatorType = OPERATOR_TYPES.find(
            operator => operator.key === value[index].filter_operator
          );
          const queryOperatorType = QUERY_OPERATOR_TYPES.find(
            operator => operator.key === value[index].query_operator
          );
          conditions.push(
            `${this.$t(
              `AUTOMATION.ADD.FORM.CONDITIONS.${actionType.attributeI18nKey}`
            )}`
          );
          conditions.push(
            `${this.$t(`FILTER.OPERATOR_LABELS.${operatorType.key}`)}`
          );
          conditions.push(`${value[index].values}`);
          conditions.push(`\n`);
          if (queryOperatorType) {
            conditions.push(
              `${this.$t(
                `FILTER.QUERY_DROPDOWN_LABELS.${queryOperatorType.attributeI18nKey}`
              )}`
            );
            conditions.push(`\n`);
          }
        });
        return conditions.join('\n');
      }
      return value.toString();
    },
    getAgentName(id) {
      return this.agents.find(agent => agent.id === id).name;
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  min-width: 50px;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.table {
  display: table;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  align-content: start;
  width: 290px;
}

.key {
  font-weight: bold;
  margin-top: 5px;
}

.value {
  white-space: pre-line;
  margin-top: 5px;
}

.values {
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  padding: 20px;
}

.time-stamp {
  font-size: 1.4rem;
}
.content {
  display: flex;
}

.address {
  white-space: pre-line;
  margin-top: 15px;

  &:first-child {
    margin-top: 5px;
  }
}

.changes {
  background-color: #f8fafc;
  overflow: auto;
  max-height: 500px;
}

.change-value {
  flex: 1;
  margin: 5px;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  word-break: break-word;
}

.before {
  background-color: #f0b3b3;
}

.after {
  background-color: #37be66;
}
.audit-changes {
  display: flex;
  flex-direction: column;
}

.audit-changes-key {
  padding: 10px;
  align-self: center;
}

.sub-title {
  text-align: center;
}

.changes-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.headers {
  display: flex;
  justify-content: space-around;
}
</style>
