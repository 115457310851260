<template>
  <mention-box :items="items" @mention-select="handleMentionClick" />
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';

export default {
  components: { MentionBox },
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    ...mapGetters({
      cannedMessages: 'getCannedResponses',
    }),
    items() {
      if (!this.searchKey) {
        return this.cannedMessages.map(cannedMessage => ({
          label: cannedMessage.short_code,
          key: cannedMessage.short_code,
          description: cannedMessage.content,
        }));
      }

      return this.cannedMessages
        .filter(cannedMessage => {
          const searchKey = this.searchKey.toLowerCase();
          const shortCode = cannedMessage.short_code.toLowerCase();
          const content = cannedMessage.content.toLowerCase();

          return shortCode.includes(searchKey) || content.includes(searchKey);
        })
        .map(cannedMessage => ({
          label: cannedMessage.short_code,
          key: cannedMessage.short_code,
          description: cannedMessage.content,
        }));
    },
  },
  watch: {
    searchKey() {
      this.fetchCannedResponses();
    },
  },
  mounted() {
    this.fetchCannedResponses();
  },
  methods: {
    fetchCannedResponses() {
      this.$store.dispatch('getCannedResponse', { searchKey: this.searchKey });
    },
    handleMentionClick(item = {}) {
      this.$emit('click', item.description);
    },
  },
};
</script>
