import {
  SET_TEAM_UI_FLAG,
  CLEAR_TEAMS,
  SET_TEAMS,
  SET_TEAM_ITEM,
  EDIT_TEAM,
  DELETE_TEAM,
} from './types/mutation.types';
import { TeamStoreState } from './types/teamsStore.types';
import { Team } from './types/team.types';
import { UiFlags } from '../../types/ui.types';

export const mutations = {
  [SET_TEAM_UI_FLAG]($state: TeamStoreState, data: UiFlags) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [CLEAR_TEAMS]: ($state: TeamStoreState) => {
    $state.records = {};
  },

  [SET_TEAMS]: ($state: TeamStoreState, data: Team[]) => {
    data.forEach(team => {
      $state.records[team.id] = {
        ...($state.records[team.id] || {}),
        ...team,
      };
    });
  },

  [SET_TEAM_ITEM]: ($state: TeamStoreState, data: Team) => {
    $state.records[data.id] = {
      ...($state.records[data.id] || {}),
      ...data,
    };
  },

  [EDIT_TEAM]: ($state: TeamStoreState, data: Team) => {
    $state.records[data.id] = data;
  },

  [DELETE_TEAM]: ($state: TeamStoreState, teamId: number) => {
    const { [teamId]: toDelete, ...records } = $state.records;
    $state.records = records;
  },
};
