<template>
  <div v-if="!authUIFlags.isFetching" id="app" class="app-wrapper app-root">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification.vue';
import WootSnackbarBox from './components/SnackbarContainer.vue';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';
import uiSettingsMixin from './mixins/uiSettings';
import logoutMixin from './mixins/logoutMixin';

export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    WootSnackbarBox,
  },

  mixins: [uiSettingsMixin, logoutMixin],

  data() {
    return {
      showAddAccountModal: false,
      userIsInactive: false,
      conversationsId: [],
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      conversations: 'getAllConversations',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      currentAccountId: 'getCurrentAccountId',
      getCurrentUserAvailability: 'getCurrentUserAvailability',
    }),
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
    currentUserAvailability() {
      return this.getCurrentUserAvailability;
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    'currentUser.id'() {
      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
  },
  mounted() {
    this.setLocale(window.chatwootConfig.selectedLocale);
  },
  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');
      const {
        locale,
        id: accountId,
        auto_set_away,
        agent_logout_timespan,
      } = this.getAccount(this.currentAccountId);
      this.setLocale(locale);
      this.checkUserInactivity(accountId, auto_set_away, agent_logout_timespan);
      this.checkUserLogoutOnInactivity(accountId, agent_logout_timespan);
    },
    checkUserInactivity(accountId, autoSetAway, agentLogoutTimespan) {
      if (accountId && autoSetAway) {
        let autoSetAwayWasSet = false;
        let timeout = false;

        const checkActivity = () => {
          this.userIsInactive = false;

          if (autoSetAwayWasSet) {
            this.$store.dispatch('updateAvailability', {
              availability: 'online',
              account_id: accountId,
            });

            autoSetAwayWasSet = false;
          }

          clearTimeout(timeout);

          timeout = setTimeout(
            async () => {
              if (
                !this.userIsInactive &&
                (this.currentUserAvailability === 'online' ||
                  this.currentUserAvailability === 'busy')
              ) {
                this.userIsInactive = true;
                autoSetAwayWasSet = true;

                await this.$store.dispatch('updateAvailability', {
                  availability: 'away',
                  account_id: accountId,
                });

                if (this.userIsInactive && agentLogoutTimespan) {
                  clearTimeout(timeout);
                  timeout = setTimeout(
                    this.logout,
                    1000 * 60 * agentLogoutTimespan
                  );
                }
              }
            },
            1000 * 60 * autoSetAway
          );
        };

        document.addEventListener('keydown', checkActivity);
        document.addEventListener('mousedown', checkActivity);
        document.addEventListener('mousemove', checkActivity);

        checkActivity();
      }
    },
    checkUserLogoutOnInactivity(accountId, agentLogoutTimespan) {
      if (accountId && agentLogoutTimespan) {
        let timeout = false;

        const checkInactivity = () => {
          clearTimeout(timeout);

          if (
            this.currentUserAvailability === 'away' ||
            this.currentUserAvailability === 'offline'
          ) {
            timeout = setTimeout(this.logout, 1000 * 60 * agentLogoutTimespan);
          }
        };

        document.addEventListener('keydown', checkInactivity);
        document.addEventListener('mousedown', checkInactivity);
        document.addEventListener('mousemove', checkInactivity);

        checkInactivity();
      }
    },
    fetchUnreadNotifications() {
      this.$store.dispatch('notifications/unReadCount');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="floating-vue/dist/style.css"></style>
<style src="@gmap-vue/v3/dist/style.css"></style>

<style lang="scss">
@import './assets/scss/app';
</style>
