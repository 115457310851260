<template>
  <div>
    <div class="multiselect-wrap--small">
      <contact-details-item
        compact
        :title="$t('CONVERSATION_SIDEBAR.ASSIGNEE_LABEL')"
        data-test-id="assign-agent"
      >
        <template #button>
          <woot-button
            v-if="showSelfAssign && isUpdating === false"
            icon="arrow-right"
            variant="link"
            size="small"
            data-test-id="self-assign-agent"
            @click="onSelfAssign"
          >
            {{
              $t('CONVERSATION_SIDEBAR.SELF_ASSIGN', {
                interpolation: { escapeValue: false },
              })
            }}
          </woot-button>
          <woot-button
            v-if="!showSelfAssign && isUpdating === false"
            icon="arrow-right"
            variant="link"
            size="small"
            data-test-id="unassign-agent"
            @click="onClickUnassignAgent"
          >
            {{ $t('CONVERSATION_SIDEBAR.UNASSIGN') }}
          </woot-button>
        </template>
      </contact-details-item>
      <multiselect-dropdown
        :is-disabled="isUpdating"
        :options="assignableAgents"
        :selected-item="assignedAgent"
        :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT')"
        :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
        :no-search-result="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')
        "
        :input-placeholder="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')
        "
        :on-open-callback="onOpenDropdown"
        data-test-id="assign-agent-dropdown"
        @click="onClickAssignAgent"
      />
    </div>
    <div class="multiselect-wrap--small">
      <contact-details-item
        compact
        :title="$t('CONVERSATION_SIDEBAR.TEAM_LABEL')"
        data-test-id="assign-team"
      >
        <template #button>
          <woot-button
            v-if="assignedTeam && isUpdating === false"
            icon="arrow-right"
            variant="link"
            size="small"
            data-test-id="unassign-team"
            @click="onClickUnassignTeam"
          >
            {{ $t('CONVERSATION_SIDEBAR.UNASSIGN') }}
          </woot-button>
        </template>
      </contact-details-item>
      <multiselect-dropdown
        :is-disabled="isUpdating"
        :options="assignableTeams"
        :selected-item="assignedTeam"
        :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.TEAM')"
        :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
        :no-search-result="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.TEAM')
        "
        :input-placeholder="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.TEAM')
        "
        data-test-id="assign-team-dropdown"
        @click="onClickAssignTeam"
      />
    </div>
    <div class="multiselect-wrap--small">
      <contact-details-item
        compact
        :title="$t('CONVERSATION_SIDEBAR.PRIORITY.TITLE')"
        data-test-id="assign-priority"
      />
      <multiselect-dropdown
        :is-disabled="isUpdating"
        :options="priorityOptions"
        :selected-item="assignedPriority"
        :multiselector-title="$t('CONVERSATION_SIDEBAR.PRIORITY.TITLE')"
        :multiselector-placeholder="
          $t('CONVERSATION_SIDEBAR.PRIORITY.PLACEHOLDER')
        "
        :no-search-result="
          $t('CONVERSATION_SIDEBAR.PRIORITY.SEARCH.NO_RESULTS')
        "
        :input-placeholder="
          $t('CONVERSATION_SIDEBAR.PRIORITY.SEARCH.PLACEHOLDER')
        "
        data-test-id="assign-priority-dropdown"
        @click="onClickAssignPriority"
      />
    </div>
    <contact-details-item
      compact
      :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_LABELS')"
    />
    <conversation-labels :conversation-id="conversationId" />

    <contact-details-item
      v-if="inboxes.length > 1"
      compact
      :title="$t('CONVERSATION_SIDEBAR.CHANGE_INBOX.TITLE')"
      data-test-id="change-inbox"
    />
    <multiselect-dropdown
      v-if="inboxes.length > 1"
      :is-disabled="isUpdating"
      :options="inboxes"
      :selected-item="assignedInbox"
      :multiselector-title="$t('CONVERSATION_SIDEBAR.CHANGE_INBOX.TITLE')"
      :multiselector-placeholder="
        $t('CONVERSATION_SIDEBAR.CHANGE_INBOX.PLACEHOLDER')
      "
      :no-search-result="
        $t('CONVERSATION_SIDEBAR.CHANGE_INBOX.SEARCH.NO_RESULTS')
      "
      :input-placeholder="
        $t('CONVERSATION_SIDEBAR.CHANGE_INBOX.SEARCH.PLACEHOLDER')
      "
      data-test-id="change-inbox-dropdown"
      @click="onClickAssignInbox"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import assignAgentMixin from 'shared/mixins/assignAgentMixin';
import ContactDetailsItem from './ContactDetailsItem.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import ConversationLabels from './labels/LabelBox.vue';
import { filterAgentsForAssignability } from 'shared/filters/agents';
import wootConstants from '../../../constants';
import { CONVERSATION_PRIORITY } from 'shared/constants/messages';
import WootButton from '../../../components/ui/WootButton.vue';

export default {
  components: {
    ContactDetailsItem,
    MultiselectDropdown,
    ConversationLabels,
    WootButton,
  },
  mixins: [alertMixin, assignAgentMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isUpdating: false,
      priorityOptions: [
        {
          id: null,
          name: this.$t('CONVERSATION_SIDEBAR.PRIORITY.OPTIONS.NONE'),
          thumbnail: `/images/priority/none.svg`,
        },
        {
          id: CONVERSATION_PRIORITY.URGENT,
          name: this.$t('CONVERSATION_SIDEBAR.PRIORITY.OPTIONS.URGENT'),
          thumbnail: `/images/priority/${CONVERSATION_PRIORITY.URGENT}.svg`,
        },
        {
          id: CONVERSATION_PRIORITY.HIGH,
          name: this.$t('CONVERSATION_SIDEBAR.PRIORITY.OPTIONS.HIGH'),
          thumbnail: `/images/priority/${CONVERSATION_PRIORITY.HIGH}.svg`,
        },
        {
          id: CONVERSATION_PRIORITY.MEDIUM,
          name: this.$t('CONVERSATION_SIDEBAR.PRIORITY.OPTIONS.MEDIUM'),
          thumbnail: `/images/priority/${CONVERSATION_PRIORITY.MEDIUM}.svg`,
        },
        {
          id: CONVERSATION_PRIORITY.LOW,
          name: this.$t('CONVERSATION_SIDEBAR.PRIORITY.OPTIONS.LOW'),
          thumbnail: `/images/priority/${CONVERSATION_PRIORITY.LOW}.svg`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      teams: 'teams/getTeams',
      inboxes: 'inboxes/getInboxes',
    }),
    assignableAgents: {
      get() {
        const agents = this.$store.getters[
          'inboxAssignableAgents/getAssignableAgents'
        ](this.inboxId);

        // FOLLOWUP Agreed to list all the agents, because this.selectedAgents was not declared here
        return filterAgentsForAssignability(agents, undefined, false);
      },
    },
    assignableTeams: {
      get() {
        return this.teams;
      },
    },
    assignedAgent: {
      get() {
        return this.currentChat.meta.assignee;
      },
    },
    assignedTeam: {
      get() {
        return this.currentChat.meta.team;
      },
    },
    assignedPriority: {
      get() {
        const selectedOption = this.priorityOptions.find(
          opt => opt.id === this.currentChat.priority
        );

        return selectedOption || this.priorityOptions[0];
      },
      set(priorityItem) {
        const conversationId = this.currentChat.id;
        const priority = priorityItem ? priorityItem.id : null;

        this.$store.dispatch('setCurrentChatPriority', priority);

        this.$store
          .dispatch('assignPriority', { conversationId, priority })
          .then(() => {
            this.showAlert(
              this.$t('CONVERSATION_SIDEBAR.PRIORITY.CHANGE_PRIORITY.SUCCESS', {
                priority: priorityItem.name,
                conversationId,
              })
            );
          });
      },
    },
    assignedInbox: {
      get() {
        return this.inboxes.find(
          inbox => inbox.id === this.currentChat.inbox_id
        );
      },
      set(inboxItem) {
        const conversationId = this.currentChat.id;
        const inboxId = inboxItem ? inboxItem.id : null;

        this.$store
          .dispatch('changeInbox', { conversationId, inboxId })
          .then(
            this.showAlert(
              this.$t('CONVERSATION_SIDEBAR.CHANGE_INBOX.SUCCESS', {
                conversationId,
              })
            )
          )
          .catch(() => {
            this.showAlert(this.$t('CONVERSATION_SIDEBAR.CHANGE_INBOX.ERROR'), {
              status: wootConstants.SNACKBAR_TYPE.ERROR,
              conversationId,
              inbox: inboxItem.name,
            });
          });
      },
    },
    showSelfAssign() {
      return !this.assignedAgent;
    },
  },
  methods: {
    onSelfAssign() {
      const {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        avatar_url,
      } = this.currentUser;
      const selfAssign = {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        thumbnail: avatar_url,
      };
      this.onClickAssignAgent(selfAssign);
    },
    onClickUnassignAgent() {
      this.onClickAssignAgent({ id: 0 });
    },
    onClickAssignAgent(selectedItem) {
      this.isUpdating = true;
      const agentId = selectedItem ? selectedItem.id : 0;
      this.assignAgent(this.currentChat.id, agentId);
      this.isUpdating = false;
    },

    onClickUnassignTeam() {
      this.onClickAssignTeam({ id: 0 });
    },

    onClickAssignTeam(selectedItemTeam) {
      this.isUpdating = true;
      const teamId = selectedItemTeam ? selectedItemTeam.id : 0;
      this.$store
        .dispatch('assignTeam', {
          conversationId: this.currentChat.id,
          teamId,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_TEAM'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.showAlert(this.$t('ERRORS.UNKNOWN'), {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        });
      this.isUpdating = false;
    },

    onClickAssignPriority(selectedPriorityItem) {
      this.isUpdating = true;

      const isSamePriority =
        this.assignedPriority &&
        this.assignedPriority.id === selectedPriorityItem.id;

      this.assignedPriority = isSamePriority ? null : selectedPriorityItem;

      this.isUpdating = false;
    },

    onClickAssignInbox(selectedInboxItem) {
      this.isUpdating = true;
      this.assignedInbox = selectedInboxItem;
      this.isUpdating = false;
    },

    onOpenDropdown() {
      return this.$store.dispatch('inboxAssignableAgents/fetch', [
        this.inboxId,
      ]);
    },
  },
};
</script>
