<template>
  <woot-modal
    :disabled="isUpdating"
    modal-type="right-aligned"
    :show="show"
    :on-close="onCancel"
  >
    <div class="column content-box">
      <woot-modal-header
        :header-title="`${$t('EDIT_CONTACT.TITLE')} - ${
          contact.name || contact.email
        }`"
        :header-content="$t('EDIT_CONTACT.DESC')"
      />
      <contact-form
        :contact="contact"
        :is-updating="isUpdating"
        :in-progress="uiFlags.isUpdating"
        :on-submit="onSubmit"
        :project-id="projectId"
        @success="onSuccess"
        @cancel="onCancel"
        @toggleIsUpdating="toggleIsUpdating"
      />
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactForm from './ContactForm.vue';
import getUpdatedContactItem from './getUpdatedContactItem';
import WootUIKit from 'components';

export default {
  components: {
    WootModalHeader: WootUIKit.ModalHeader,
    WootModal: WootUIKit.Modal,
    ContactForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    projectId: {
      type: String,
      default: '',
    },
  },
  emits: ['cancel'],
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },

  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    toggleIsUpdating(isUpdating) {
      this.isUpdating = isUpdating;
    },
    async onSubmit(contactItem) {
      const updatedContactItem = getUpdatedContactItem(contactItem.profile);

      await this.$store.dispatch('contacts/update', {
        ...contactItem,
        profile: updatedContactItem,
        projectId: this.projectId,
      });

      await this.$store.dispatch(
        'contacts/fetchContactableInbox',
        this.contact.id
      );
    },
  },
};
</script>
