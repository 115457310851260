<template>
  <div class="columns profile--settings" data-test-id="settings">
    <form @submit.prevent="updateUser('profile')">
      <div class="small-12 row profile--settings--row">
        <div class="columns small-3">
          <h4 class="block-title">
            {{ $t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.NOTE') }}</p>
        </div>
        <div class="columns small-9 medium-5">
          <woot-avatar-uploader
            ref="avatarUploader"
            :label="$t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL')"
            :src="avatarUrl"
            :delete-avatar="showDeleteButton()"
            @change="handleImageUpload"
            @onAvatarDelete="deleteAvatar"
          />
          <label :class="{ error: v$.displayName.$error }">
            {{ $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL') }}
            <input
              v-model="displayName"
              type="text"
              :placeholder="
                $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER')
              "
              data-test-id="display_name_input"
              class="input-name-container"
              @input="v$.displayName.$touch"
            />
          </label>
          <label v-if="isAgentMaskingEnabled">
            {{ $t('PROFILE_SETTINGS.FORM.AGENT_MASKING_ALIAS_LABEL') }}
            <input
              :value="alias"
              type="text"
              data-test-id="profile-settings-agent-readonly-alias"
              :disabled="true"
              class="input-name-container"
            />
          </label>
          <woot-button
            type="submit"
            :data-test-id="'update_display_name'"
            :is-loading="isProfileUpdating"
          >
            {{ $t('PROFILE_SETTINGS.BTN_TEXT') }}
          </woot-button>
        </div>
      </div>
    </form>
    <!-- <message-signature /> -->
    <notification-settings />
    <div v-if="featureUseAICopilotWorkspace" class="profile--settings--row row">
      <div class="columns small-3">
        <h4 class="block-title">
          {{ $t('PROFILE_SETTINGS.FORM.AGENT_ASSIST_WORKSPACE_SECTION.TITLE') }}
        </h4>
        <p>
          {{ $t('PROFILE_SETTINGS.FORM.AGENT_ASSIST_WORKSPACE_SECTION.NOTE') }}
        </p>
      </div>
      <div class="columns small-9">
        <div v-if="isAICopilotWorkspaceOverrided">
          <p class="notification-override-text">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AGENT_ASSIST_WORKSPACE_SECTION.OVERRIDE_NOTIFICATION'
              )
            }}
          </p>
        </div>
        <div>
          <input
            id="enable_agent_assist_workspace"
            v-model="enableAawView"
            data-test-id="profile-settings-enable-ai-copilot-workspace"
            class="notification--checkbox"
            type="radio"
            :disabled="isAICopilotWorkspaceOverrided"
            :value="true"
            @input="handleAAWInput"
          />
          <label
            for="enable_agent_assist_workspace"
            class="toggle-checkbox-label-container"
          >
            <div>
              {{ $t('GENERAL_SETTINGS.FORM.TOGGLE_AGENT_ASSIST_WORKSPACE') }}
            </div>
          </label>
        </div>
        <div>
          <input
            id="disable_agent_assist_workspace"
            v-model="enableAawView"
            data-test-id="profile-settings-disable-ai-copilot-workspace"
            class="notification--checkbox"
            type="radio"
            :disabled="isAICopilotWorkspaceOverrided"
            :value="false"
            @input="handleAAWInput"
          />
          <label
            for="disable_agent_assist_workspace"
            class="toggle-checkbox-label-container"
          >
            <div>
              {{
                $t(
                  'PROFILE_SETTINGS.FORM.AGENT_ASSIST_WORKSPACE_SECTION.DISABLE_AGENT_ASSIST_WORKSPACE'
                )
              }}
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="profile--settings--row row">
      <div class="columns small-3">
        <h4 class="block-title">
          {{ $t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.TITLE') }}
        </h4>
        <p>
          {{ $t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.NOTE') }}
        </p>
      </div>
      <div class="columns small-9 medium-5 card-preview">
        <button
          v-for="keyOption in keyOptions"
          :key="keyOption.key"
          :data-test-id="`profile-settings-${keyOption.key}-key`"
          class="preview-button"
          @click="toggleEditorMessageKey(keyOption.key)"
        >
          <preview-card
            :heading="keyOption.heading"
            :content="keyOption.content"
            :src="keyOption.src"
            :active="isEditorHotKeyEnabled(uiSettings, keyOption.key)"
          />
        </button>
      </div>
    </div>
    <div class="profile--settings--row row">
      <div class="columns small-3">
        <h4 class="block-title">
          {{ $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.TITLE') }}
        </h4>
        <p>
          {{
            useInstallationName({
              str: $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE'),
              installationName: globalConfig.installationName,
            })
          }}
        </p>
      </div>
      <div class="columns small-9 medium-5">
        <woot-code :script="currentUser.access_token" />
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from '@vuelidate/validators';
import { isValidImage } from 'shared/helpers/Validators';
import { mapGetters } from 'vuex';
import { clearCookiesOnLogout } from 'dashboard/store/utils/api';
import NotificationSettings from './NotificationSettings.vue';
import alertMixin from 'shared/mixins/alertMixin';
import uiSettingsMixin, {
  isEditorHotKeyEnabled,
} from 'dashboard/mixins/uiSettings';
import PreviewCard from 'dashboard/components/ui/PreviewCard.vue';
import cmdEditor from '@/dashboard/assets/images/cmd-editor.png';
import enterEditor from '@/dashboard/assets/images/enter-editor.png';
import noneEditor from '@/dashboard/assets/images/none-editor.png';

import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import wootConstants from '../../../../constants';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import WootCode from 'components/Code.vue';
import WootAvatarUploader from 'components/widgets/forms/AvatarUploader.vue';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'ProfileSettings',
  components: {
    WootAvatarUploader,
    WootCode,
    WootButton,
    NotificationSettings,
    PreviewCard,
  },
  mixins: [alertMixin, globalConfigMixin, uiSettingsMixin],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      avatarFile: '',
      avatarUrl: '',
      name: '',
      alias: '',
      displayName: '',
      email: '',
      isProfileUpdating: false,
      errorMessage: '',
      enableAawView: null,
      featureUseAICopilotWorkspace:
        window.chatwootConfig.featureUseAICopilotWorkspace,
      keyOptions: [
        {
          key: 'none',
          src: noneEditor,
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.NONE.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.NONE.CONTENT'
          ),
        },
        {
          key: 'enter',
          src: enterEditor,
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.CONTENT'
          ),
        },
        {
          key: 'cmd_enter',
          src: cmdEditor,
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.CONTENT'
          ),
        },
      ],
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
    displayName: {},
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),

    isAgentMaskingEnabled() {
      return this.getAccount(this.accountId)?.is_agent_masking_enabled;
    },

    isAICopilotWorkspaceOverrided() {
      return this.getAccount(this.accountId)?.override_enable_aaw_view;
    },
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
  },
  methods: {
    handleAAWInput(e) {
      this.enableAawView = e.target.value === 'true';
      this.updateUISettings({
        enable_aaw_view: this.enableAawView,
      });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'), {
        status: wootConstants.SNACKBAR_TYPE.SUCCESS,
      });
    },
    initializeUser() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.avatarUrl = this.currentUser.avatar_url;
      this.displayName = this.currentUser.display_name;
      this.alias = this.currentUser.alias;
      this.enableAawView = this.uiSettings.enable_aaw_view;
    },
    isEditorHotKeyEnabled,
    async updateUser() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
        return;
      }

      this.isProfileUpdating = true;
      const hasEmailChanged = this.currentUser.email !== this.email;
      try {
        await this.$store.dispatch('updateProfile', {
          name: this.name,
          email: this.email,
          avatar: this.avatarFile,
          displayName: this.displayName,
        });
        this.isProfileUpdating = false;
        if (hasEmailChanged) {
          clearCookiesOnLogout();
          this.errorMessage = this.$t('PROFILE_SETTINGS.AFTER_EMAIL_CHANGED');
        }
        this.errorMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
        this.action = {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        };
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.error) {
          this.errorMessage = error.response.data.error;
        }
        this.action = {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        };
      } finally {
        this.isProfileUpdating = false;
        this.showAlert(this.errorMessage, this.action);
      }
    },
    handleImageUpload({ file, url }) {
      if (isValidImage(file)) {
        this.avatarFile = file;
        this.avatarUrl = url;
        return;
      }

      this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_TYPE_ERROR'), {
        status: wootConstants.SNACKBAR_TYPE.ERROR,
      });
      this.$refs.avatarUploader.clearFileInput();
    },
    async deleteAvatar() {
      try {
        await this.$store.dispatch('deleteAvatar');
        this.avatarUrl = '';
        this.avatarFile = '';
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_SUCCESS'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_FAILED'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    showDeleteButton() {
      return this.avatarUrl && !this.avatarUrl.includes('www.gravatar.com');
    },
    toggleEditorMessageKey(key) {
      this.updateUISettings({ editor_message_key: key });
      this.showAlert(
        this.$t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.UPDATE_SUCCESS')
      );
    },
  },
};
</script>

<style lang="scss">
@import 'dashboard/assets/scss/variables.scss';
@import 'dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}
.avatar-delete-btn {
  margin-top: var(--space-two);
  margin-bottom: var(--space-three);
}
.input-name-container {
  margin-top: var(--space-one);
  margin-bottom: var(--space-two);
}

.profile--settings--row {
  @include border-normal-bottom;
  align-items: center;
  display: flex;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
  .notification-override-text {
    color: #cc0000;
  }
  .card-preview {
    display: flex;
    flex-direction: row;
    .preview-button {
      cursor: pointer;
      margin-right: var(--space-normal);
    }
  }
}
</style>
