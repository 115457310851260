import types from '../mutation-types';
import authAPI from '../../api/auth';
import { setUser, clearCookiesOnLogout } from '../utils/api';
import { getLoginRedirectURL } from '../../helper/URLHelper';

const initialState = {
  currentUser: {
    id: null,
    account_id: null,
    accounts: [],
    email: null,
    name: null,
  },
  uiFlags: {
    isFetching: true,
  },
};

// getters
export const getters = {
  isLoggedIn($state) {
    return !!$state.currentUser.id;
  },

  getCurrentUserID($state) {
    return $state.currentUser.id;
  },

  getUISettings($state) {
    return $state.currentUser.ui_settings || {};
  },

  getAuthUIFlags($state) {
    return $state.uiFlags;
  },

  getCurrentUserAvailability($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount.availability;
  },

  getCurrentAccountId() {
    const accountId = window.WOOT.router.currentRoute.value?.params?.accountId;

    if (accountId) {
      return Number(accountId);
    }
    return null;
  },

  getCurrentRoles($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount.roles;
  },

  getCurrentUser($state) {
    return $state.currentUser;
  },

  getMessageSignature($state) {
    const { message_signature: messageSignature } = $state.currentUser;

    return messageSignature || '';
  },

  getCurrentAccount($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount || {};
  },

  getUserAccounts($state) {
    const { accounts = [] } = $state.currentUser;
    return accounts;
  },
};

// actions
export const actions = {
  login(actionObj, { ssoAccountId, ...credentials }) {
    return new Promise((resolve, reject) => {
      authAPI
        .login(credentials)
        .then(response => {
          window.location = getLoginRedirectURL(ssoAccountId, response.data);

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async validityCheck(context) {
    try {
      const response = await authAPI.validityCheck();
      const currentUser = response.data.payload.data;
      setUser(currentUser);
      context.commit(types.SET_CURRENT_USER, currentUser);
      return currentUser;
    } catch (error) {
      if (error?.response?.status === 401) {
        clearCookiesOnLogout();
      }
      return null;
    }
  },
  async setUser({ commit, dispatch }) {
    if (authAPI.hasAuthCookie()) {
      await dispatch('validityCheck');
    } else {
      commit(types.CLEAR_USER);
    }
    commit(types.SET_CURRENT_USER_UI_FLAGS, { isFetching: false });
  },
  logout({ commit }) {
    commit(types.CLEAR_USER);
  },

  updateProfile: async ({ commit }, params) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await authAPI.profileUpdate(params);
      commit(types.SET_CURRENT_USER, response.data);
    } catch (error) {
      throw error;
    }
  },

  deleteAvatar: async () => {
    try {
      await authAPI.deleteAvatar();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  updateUISettings: async ({ commit }, params) => {
    try {
      commit(types.SET_CURRENT_USER_UI_SETTINGS, params);
      const response = await authAPI.updateUISettings(params);
      commit(types.SET_CURRENT_USER, response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  updateAvailability: async ({ commit, dispatch }, params) => {
    try {
      const response = await authAPI.updateAvailability(params);
      const userData = response.data;
      const { id } = userData;
      commit(types.SET_CURRENT_USER, response.data);
      dispatch('agents/updatePresence', { id, status: params.availability });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  setAgentStatus: async ({ dispatch }, params) => {
    try {
      const response = await authAPI.setAgentStatus(params);
      const userData = response.data;
      const { id } = userData;
      dispatch('agents/updatePresence', { id, status: params.status });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },

  setCurrentUserAvailability(
    { commit, state: $state, getters: $getters },
    data
  ) {
    if (data[$state.currentUser.id]) {
      commit(types.SET_CURRENT_USER_AVAILABILITY, {
        availability: data[$state.currentUser.id],
        currentAccountId: $getters.getCurrentAccountId,
      });
    }
  },
};

// mutations
export const mutations = {
  // This is a hack, implemented because we have accounts stored in two places in the state. In the future we should move as much state out of the auth module as possible into other modules like, "accounts".
  // This hack updates the current account state in the auth module for the agent masking flag whenever the agent masking flag is updated in the normal accounts module.
  [types.SET_CURRENT_ACCOUNT_AGENT_MASKING_FLAG]($state, newAccount) {
    const currentAccountIndexOption = $state.currentUser.accounts.findIndex(
      oldAccount => oldAccount.id === newAccount.id
    );

    if (currentAccountIndexOption !== -1) {
      const finalAccounts = [...$state.currentUser.accounts];
      finalAccounts[currentAccountIndexOption] = {
        ...finalAccounts[currentAccountIndexOption],
        is_agent_masking_enabled: newAccount.is_agent_masking_enabled,
      };

      $state.currentUser.accounts = finalAccounts;
    }
  },
  [types.SET_CURRENT_USER_AVAILABILITY](
    $state,
    { availability, currentAccountId }
  ) {
    $state.currentUser.availability = availability;
    // We also set the availability of the agent in its current account, this way we can show the update in realtime
    const accountIndex = $state.currentUser.accounts.findIndex(
      account => account.id === currentAccountId
    );
    $state.currentUser.accounts[accountIndex].availability = availability;
  },
  [types.CLEAR_USER]($state) {
    $state.currentUser = initialState.currentUser;
  },
  [types.SET_CURRENT_USER]($state, currentUser) {
    $state.currentUser = currentUser;
  },
  [types.SET_CURRENT_USER_UI_SETTINGS]($state, { uiSettings }) {
    $state.currentUser.ui_settings = {
      ...$state.currentUser.ui_settings,
      ...uiSettings,
    };
  },

  [types.SET_CURRENT_USER_UI_FLAGS]($state, { isFetching }) {
    $state.uiFlags = { isFetching };
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
