import * as types from '../../mutation-types';
import ConversationAPI from '../../../api/conversations';
import {
  ConversationLabelsState,
  ConversationLabelsUpdatePayload,
  SetConversationLabelsPayload,
  ConversationLabelsFlags,
} from './conversationLabels.types';
import { ActionContext } from 'vuex';
import { Conversation } from '../../types/conversation.types';

const state: ConversationLabelsState = {
  records: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isError: false,
  },
};

export const getters = {
  getUIFlags($state: ConversationLabelsState) {
    return $state.uiFlags;
  },
  getConversationLabels: ($state: ConversationLabelsState) => (id: string) => {
    return $state.records[Number(id)] || [];
  },
};

export const actions = {
  get: async (
    {
      commit,
    }: Pick<
      ActionContext<ConversationLabelsState, ConversationLabelsState>,
      'commit'
    >,
    conversationId: string | number
  ) => {
    commit(types.default.SET_CONVERSATION_LABELS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await ConversationAPI.getLabels(conversationId);
      commit(types.default.SET_CONVERSATION_LABELS, {
        id: conversationId,
        data: response.data.payload,
      });
      commit(types.default.SET_CONVERSATION_LABELS_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_CONVERSATION_LABELS_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  update: async (
    {
      commit,
    }: Pick<
      ActionContext<ConversationLabelsState, ConversationLabelsState>,
      'commit'
    >,
    { conversationId, labels }: ConversationLabelsUpdatePayload
  ) => {
    commit(types.default.SET_CONVERSATION_LABELS_UI_FLAG, {
      isUpdating: true,
    });
    try {
      const response = await ConversationAPI.updateLabels(
        conversationId,
        labels
      );
      commit(types.default.SET_CONVERSATION_LABELS, {
        id: conversationId,
        data: response.data.payload,
      });
      commit(types.default.SET_CONVERSATION_LABELS_UI_FLAG, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.default.SET_CONVERSATION_LABELS_UI_FLAG, {
        isUpdating: false,
        isError: true,
      });
    }
  },
  setBulkConversationLabels(
    {
      commit,
    }: Pick<
      ActionContext<ConversationLabelsState, ConversationLabelsState>,
      'commit'
    >,
    conversations: Conversation[]
  ) {
    commit(types.default.SET_BULK_CONVERSATION_LABELS, conversations);
  },
  setConversationLabel(
    {
      commit,
    }: Pick<
      ActionContext<ConversationLabelsState, ConversationLabelsState>,
      'commit'
    >,
    { id, data }: SetConversationLabelsPayload
  ) {
    commit(types.default.SET_CONVERSATION_LABELS, { id, data });
  },
};

export const mutations = {
  [types.default.SET_CONVERSATION_LABELS_UI_FLAG](
    $state: ConversationLabelsState,
    data: ConversationLabelsFlags
  ) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_CONVERSATION_LABELS]: (
    $state: ConversationLabelsState,
    { id, data }: SetConversationLabelsPayload
  ) => {
    $state.records[id] = data;
  },
  [types.default.SET_BULK_CONVERSATION_LABELS]: (
    $state: ConversationLabelsState,
    conversations: Conversation[]
  ) => {
    conversations.forEach(conversation => {
      $state.records[conversation.id] = conversation.labels || [];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
