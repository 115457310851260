<template>
  <div class="container" :class="{ smallContainer: isSideMenuOpen }">
    <p class="title">{{ title }}</p>
    <p v-if="subTitle" class="subtitle">{{ subTitle }}</p>
    <p v-dompurify-html="body" class="body">
      {{ body }}
    </p>
    <div class="action" :class="{ smallSuggestions: isSideMenuOpen }">
      <woot-button
        v-for="(button, index) in buttons"
        :key="index"
        @click="onForwardSuggestedReply(button.title)"
      >
        {{ button.title }}
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    isSideMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['forwardSuggestedReply'],
  computed: {
    title() {
      return this.data[0].text;
    },
    subTitle() {
      return this.data[1].text;
    },
    body() {
      return this.data[2].text;
    },
    buttons() {
      return this.data[3].actions;
    },
  },
  methods: {
    onForwardSuggestedReply(reply) {
      this.$parent.$parent.$parent.$parent.$emit(
        'forwardSuggestedReply',
        reply
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import 'dashboard/assets/scss/woot';

.container {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 400px;
  @include breakpoint(xlargeHD up) {
    width: 550px;
  }
  padding: 15px 15px 15px 0;
}

.smallContainer {
  min-width: 230px;
  width: 230px;
  padding-right: 0;
  padding-left: 10px;
  @include breakpoint(xlargeHD up) {
    width: 550px;
    padding: 15px 15px 15px 0;
  }
}
.title {
  font-size: 1.6rem;
  color: #1a48a2;
  @include breakpoint(xlargeHD up) {
    font-size: 2rem;
  }
}
.subtitle {
  font-size: 1.2rem;
  @include breakpoint(xlargeHD up) {
    font-size: 1.2rem;
  }
}

.body {
  word-wrap: break-word;
  text-align: justify;
  font-size: 1.3rem;
  white-space: pre-wrap;
  overflow: auto;

  @include breakpoint(xlargeHD up) {
    font-size: 1.5rem;
  }
}

.action {
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    width: 100%;
    height: auto;
    margin: 2px;
    word-break: break-all;
    padding: 10px;
  }
}

.smallSuggestions {
  padding-right: 0;
  button {
    width: 220px;
    @include breakpoint(xlargeHD up) {
      width: 100%;
    }
  }
}
</style>
